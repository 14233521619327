import React, { useEffect, useState } from 'react';

import { Col, Row, Spinner } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import GeoMaps from './GeoFence';
import Content from '../../../layout/content/Content';
import Head from '../../../layout/head/Head';
import { ViewAllGeoFencingThunk } from '../../../redux/GeoFencingSlice/ViewAllGeoFencingSlice';


const ViewAllGeoMap = () => {
    const dispatch = useDispatch();
    const [state, setState] = useState([])
    const { paths } = state

    const {loading, viewAllgeoFencingData} = useSelector((state)=> state.geoFencing.viewAllGeoFencing)

    useEffect(()=>{
        dispatch(ViewAllGeoFencingThunk())
    },[dispatch]);

    return (
        <React.Fragment>
            <Content>
            <Head title="All Geo Fence" />
                <h3> All Geo Fence</h3>
                <Row>
                    <Col xl="12">
                        {loading ?
                        <div className='w-100 d-flex justify-content-center align-items-center' style={{height: "80vh"}}>
                            <Spinner/>
                        </div>
                        : 
                        <GeoMaps
                            // apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                            polygons={viewAllgeoFencingData?.polygons}
                            pickupPoints={viewAllgeoFencingData?.pickupPoints}
                            paths={paths}
                            point={paths => setState({ paths })}
                        />
                    }
                    </Col>
                </Row>
            </Content>
        </React.Fragment>
    )
}

export default ViewAllGeoMap;
