import { combineReducers } from "@reduxjs/toolkit";
import AddInstallmentPackageSlice from "./AddInstallmentPackageSlice";
import UpdateInstallmentPackageSlice from "./UpdateInstallmentPackageSlice";
import GetInstallmentPackagesSlice from "./GetInstallmentPackagesSlice";
import AddRegularPackageSlice from "./AddRegularPackageSlice";
import UpdateRegularPackageSlice from "./UpdateRegularPackageSlice";
import DeleteRegularPackageSlice from "./DeleteRegularPackageSlice";

export const PackageReducers = combineReducers({
    getInstallmentPackages: GetInstallmentPackagesSlice,
    addInstallmentPackage: AddInstallmentPackageSlice,
    updateInstallmentPackage: UpdateInstallmentPackageSlice,
    addRegularPackage: AddRegularPackageSlice,
    updateRegularPackage: UpdateRegularPackageSlice,
    deleteRegularPackage: DeleteRegularPackageSlice
})