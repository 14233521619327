import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../../utils/ApiEndpoints";

// add student Invoice
const AddStudentInvoiceThunk = createAsyncThunk(
    "AddStudentInvoice/AddStudentInvoiceThunk",
    async ({ studentId, reason, amount, packageId }, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${ApiEndpoints.StudentsEndPoint.ManualInvoice.AddStudentInvoice}?studentId=${studentId}&packageId=${packageId}`,
                { reason, amount },
                {
                headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
                }
            );
            return response.data;
        } catch (error) {
        console.log("error", error);
            return rejectWithValue(error.response.data);
        }
    }
);

const AddStudentInvoiceSlice = createSlice({
    name: "AddStudentInvoice",
    initialState: {
        AddStudentInvoiceData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetAddStudentInvoiceData: (state, action) => {
        state.AddStudentInvoiceData = [];
        state.success = false;
        state.error = false;
        state.loading = false;
        state.errorMessage = "";
        },
    },
    extraReducers: {
        [AddStudentInvoiceThunk.pending]: (state, action) => {
        state.loading = true;
        state.success = false;
        state.error = false;
        },
        [AddStudentInvoiceThunk.fulfilled]: (state, action) => {
        state.AddStudentInvoiceData = [...state.AddStudentInvoiceData, action.payload.data];
        state.success = true;
        state.loading = false;
        },
        [AddStudentInvoiceThunk.rejected]: (state, action) => {
        state.errorMessage = action.payload.message;
        state.error = true;
        state.loading = false;
        },
    },
});

export default AddStudentInvoiceSlice.reducer;

export const { ResetAddStudentInvoiceData } = AddStudentInvoiceSlice.actions;

export { AddStudentInvoiceThunk };
