import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const GetInstructorDevicesDetailThunk = createAsyncThunk("GetInstructorDevicesDetail/GetInstructorDevicesDetailThunk", async (_, { rejectWithValue }) => {
    try {
        const response = await axios.get(
            `${ApiEndpoints.InstructorDevices.GetInstructorDevicesDetail}`,
            {
                headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
            }
        );
        return response.data;
    } catch (error) {
        console.log("error", error);
        return rejectWithValue(error.response.data);
    }
}
);

const GetInstructorDevicesDetailSlice = createSlice({
    name: "GetInstructorDevicesDetail",
    initialState: {
        GetInstructorDevicesData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetGetInstructorDevicesDetailData: (state, action) => {
            state.GetInstructorDevicesData = [];
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },

    extraReducers: {
        [GetInstructorDevicesDetailThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [GetInstructorDevicesDetailThunk.fulfilled]: (state, action) => {
            state.GetInstructorDevicesData = action.payload.details;
            state.success = true;
            state.loading = false;
        },
        [GetInstructorDevicesDetailThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload.message;
            state.error = true;
            state.loading = false;
            state.GetInstructorDevicesData = [];
        },
    },
});

export default GetInstructorDevicesDetailSlice.reducer;

export const { ResetGetInstructorDevicesDetailData } = GetInstructorDevicesDetailSlice.actions;

export { GetInstructorDevicesDetailThunk };
