import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormGroup, Modal, ModalBody, Row, Spinner } from 'reactstrap';
import { Icon, RSelect } from '../../../components/Component';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ResetUpdateRegularPackageData, UpdateRegularPackageThunk } from '../../../redux/PackagesSlice/UpdateRegularPackageSlice';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { GetPackagesThunk } from '../../../redux/StudentsSlice/GetPackagesSlice';

const UpdateRegularPackageModal = ({ modal, setModal, packageDetail }) => {
    const dispatch = useDispatch();

    const { loading, success, error, errorMessage } = useSelector((state) => state.packages.updateRegularPackage)
    const [formData, setFormData] = useState({
        name: "",
        bulletPoints: [""],
        centralPickupNumberOfLessons: 0,
        homePickupNumberOfLessons: 0,
        order: 1,
        region: "",
        isFullCourse: true,
        numberOfHours: 0,
        isRoadTest: true,
        price: 0,
        popular: true,
        homePickupPerks: [""],
        centralPickupPerks: [""]
    });

    useEffect(() => {
        if(packageDetail) {
            setFormData({
                name: packageDetail.name,
                centralPickupNumberOfLessons: packageDetail.centralPickupNumberOfLessons,
                homePickupNumberOfLessons: packageDetail.homePickupNumberOfLessons,
                order: packageDetail.order,
                region: packageDetail.region,
                isFullCourse: packageDetail.isFullCourse,
                numberOfHours: packageDetail.numberOfHours,
                isRoadTest: packageDetail.isRoadTest,
                price: packageDetail.price,
                popular: packageDetail.popular,
                homePickupPerks: packageDetail.homePickupPerks,
                centralPickupPerks: packageDetail.centralPickupPerks
            })
        }
    }, [packageDetail]);

    const onInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const handleUpdateRegularPackage = (e) => {
        e.preventDefault();
        dispatch(UpdateRegularPackageThunk({
            packageId: packageDetail?._id,
            name: formData.name,
            centralPickupNumberOfLessons: formData.centralPickupNumberOfLessons ,
            homePickupNumberOfLessons: formData.homePickupNumberOfLessons,
            order: formData.order,
            region: formData.region,
            isFullCourse: formData.isFullCourse,
            numberOfHours: formData.numberOfHours,
            isRoadTest: formData.isRoadTest,
            price: formData.price,
            popular: formData.popular,
            homePickupPerks: formData.homePickupPerks,
            centralPickupPerks: formData.centralPickupPerks
        }))
    }

    const { errors, register, handleSubmit } = useForm();

    if(success) {
        toast.success("Package has been updated successfully.");
        dispatch(ResetUpdateRegularPackageData());
        dispatch(GetPackagesThunk());
        setModal();
    }

    if(error) {
        toast.error(errorMessage);
        dispatch(ResetUpdateRegularPackageData());
        setModal();
    }

    return (
        <React.Fragment>
            <Modal
                isOpen={modal.update}
                toggle={() => setModal(false)}
                className="modal-dialog-centered"
                size="xl"
            >
                <ModalBody>
                    <div
                        onClick={(ev) => {
                            ev.preventDefault();
                            setModal(false);
                        }}
                        className="close cursor_pointer"
                    >
                        <Icon name="cross-sm"></Icon>
                    </div>
                    <div className="p-2">
                        <h5 className="title">Update Regular Package</h5>
                        <div className="mt-4">
                            <Form className="row gy-4 mt-4" onSubmit={handleSubmit(handleUpdateRegularPackage)}>
                                <Col md="12">
                                    <FormGroup>
                                        <label className="form-label">Package Name</label>
                                        <input
                                            className="form-control"
                                            ref={register({ required: "This field is required" })}
                                            type="text"
                                            name="name"
                                            onChange={(e) => onInputChange(e)}
                                            defaultValue={formData.name}
                                            placeholder="Enter Package Name"
                                        />
                                        {errors.name && <span className="invalid">{errors.name.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Price</label>
                                        <input
                                            className="form-control"
                                            ref={register({ required: "This field is required" })}
                                            type="number"
                                            name="price"
                                            onChange={(e) => onInputChange(e)}
                                            defaultValue={formData.price}
                                            placeholder="Enter a price"
                                        />
                                        {errors.price && <span className="invalid">{errors.price.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Popular</label>
                                        <RSelect
                                            ref={register({ required: "This field is required" })}
                                            defaultValue={{value: formData.popular, label: formData.popular ? "True" : "False" }}
                                            options={[{value: true, label: "True" }, {value: false, label: "False" }]}
                                            name="popular"
                                            // value={formData.popular}
                                            onChange={(e) => setFormData({ ...formData, popular: e.value })}
                                            placeholder=""
                                        />
                                        {errors.popular && <span className="invalid">{errors.popular.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Road Test</label>
                                        <RSelect
                                            ref={register({ required: "This field is required" })}
                                            options={[{value: true, label: "True" }, {value: false, label: "False" }]}
                                            name="isRoadTest"
                                            onChange={(e) => setFormData({ ...formData, isRoadTest: e.value })}
                                            defaultValue={{value: formData.isRoadTest, label: formData.isRoadTest ? "True" : "False" }}
                                            placeholder=""
                                        />
                                        {errors.isRoadTest && <span className="invalid">{errors.isRoadTest.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Full Course</label>
                                        <RSelect
                                            ref={register({ required: "This field is required" })}
                                            options={[{value: true, label: "True" }, {value: false, label: "False" }]}
                                            name="isFullCourse"
                                            onChange={(e) => setFormData({ ...formData, isFullCourse: e.value })}
                                            defaultValue={{value: formData.isFullCourse, label: formData.isFullCourse ? "True" : "False" }}
                                            placeholder=""
                                        />
                                        {errors.isFullCourse && <span className="invalid">{errors.isFullCourse.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Package Flow</label>
                                        <RSelect
                                            ref={register({ required: "This field is required" })}
                                            defaultValue={{value: formData.packageFlow, label: formData.packageFlow === "in_car_lessons" ? "In Car Lessons" : "Knowledge Test" }}

                                            options={[{value: "in_car_lessons", label: "In Car Lessons" }, {value: "knowledge_test", label: "Knowledge Test" }]}
                                            name="packageFlow"
                                            onChange={(e) => setFormData({ ...formData, packageFlow: e.value })}
                                            // defaultValue={formData.review}
                                            placeholder="Select a Package Flow"
                                        />
                                        {errors.packageFlow && <span className="invalid">{errors.packageFlow.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Order Number</label>
                                        <input
                                            className="form-control"
                                            ref={register({ required: "This field is required" })}
                                            type="number"
                                            name="order"
                                            onChange={(e) => onInputChange(e)}
                                            defaultValue={formData.order}
                                            placeholder="Enter an order number"
                                        />
                                        {errors.order && <span className="invalid">{errors.order.message}</span>}
                                    </FormGroup>
                                </Col>

                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Region</label>
                                        <input
                                            className="form-control"
                                            ref={register({ required: "This field is required" })}
                                            type="text"
                                            name="region"
                                            onChange={(e) => onInputChange(e)}
                                            defaultValue={formData.region}
                                            placeholder="Enter a region"
                                        />
                                        {errors.region && <span className="invalid">{errors.region.message}</span>}
                                    </FormGroup>
                                </Col>

                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Number of hours</label>
                                        <input
                                            className="form-control"
                                            ref={register({ required: "This field is required" })}
                                            type="number"
                                            name="numberOfHours"
                                            onChange={(e) => onInputChange(e)}
                                            value={formData.numberOfHours}
                                            defaultValue={formData.numberOfHours}
                                            placeholder="Enter a number of hours"
                                        />
                                        {errors.numberOfHours && <span className="invalid">{errors.numberOfHours.message}</span>}
                                    </FormGroup>
                                </Col>
                                { /*  ****************************** Central PICKUP *************************** */}
                                <Col md="6" style={{ border: "1px solid #000", padding: "10px", borderRadius: "5px" }}>
                                    <h6 className='d-flex justify-content-center w-100'>Central Pickup</h6>
                                    <Col md="12" className='p-0'>
                                        <FormGroup>
                                            <label className="form-label">Number of lessons</label>
                                            <input
                                                className="form-control"
                                                ref={register({ required: "This field is required" })}
                                                type="number"
                                                name="centralPickupNumberOfLessons"
                                                onChange={(e) => onInputChange(e)}
                                                value={formData.centralPickupNumberOfLessons}
                                                defaultValue={formData.centralPickupNumberOfLessons}
                                                placeholder="Enter a lesson number"
                                            />
                                            {errors.centralPickupNumberOfLessons && <span className="invalid">{errors.centralPickupNumberOfLessons.message}</span>}
                                        </FormGroup>
                                    </Col>
                                    <Col md="12">
                                        <span className='d-flex justify-content-center w-100 mt-4 mb-2' style={{fontWeight: 800, fontSize: "16px"}}> Perks</span>
                                    </Col>

                                    {formData.centralPickupPerks.map((centralPickupPerk, index)=> {
                                        return (
                                            <>
                                                <Row className='mt-2'>
                                                    <Col md="11">
                                                        <FormGroup>
                                                            <input
                                                                type="text"
                                                                id="centralPickupPerk"
                                                                className="form-control"
                                                                name="centralPickupPerk"
                                                                value={centralPickupPerk}
                                                                onChange={(e) => {
                                                                    const updatedPoints = [...formData.centralPickupPerks];
                                                                    updatedPoints[index] = e.target.value;
                                                                    setFormData({ ...formData, centralPickupPerks: updatedPoints });
                                                                
                                                                }}
                                                                placeholder="Enter a perk"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    {(formData.centralPickupPerks.length > 1 ) && (
                                                        <Col md="1" className="d-flex justify-content-center align-items-center p-0">
                                                            <div
                                                                color="primary"
                                                                className='cursor_pointer'
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    const fileteredcentralPickupPerks = formData.centralPickupPerks.filter((_, idx) => index !== idx);
                                                                    setFormData({...formData, centralPickupPerks: fileteredcentralPickupPerks });
                                                                }}
                                                                >
                                                                <Icon className="d-flex align-items-center" style={{ fontSize: '24px' }} name="cross" size="2x"></Icon>
                                                            </div>
                                                        </Col>
                                                    )}
                                                </Row>
                                            </>
                                        )
                                    })}
                                    <Col md="1" className="d-flex justify-content-center align-items-center mt-4 p-0">
                                        <div
                                            color="primary"
                                            className='cursor_pointer'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setFormData({ ...formData, centralPickupPerks: [...formData.centralPickupPerks, ""]})
                                            }}
                                        >
                                            <Icon className="d-flex align-items-center" style={{ fontSize: '24px' }} name="plus" size="3x"></Icon>
                                        </div>
                                    </Col>
                                </Col>
                                { /* ************************** HOME PICKUP  ********************************** */}
                                <Col md="6" style={{ border: "1px solid #000", padding: "10px", borderRadius: "5px" }}>
                                    <h6 className='d-flex justify-content-center w-100'>Home Pickup</h6>
                                    <Col md="12" className='p-0 '>
                                        <FormGroup>
                                            <label className="form-label">Number of lessons</label>
                                            <input
                                                className="form-control"
                                                ref={register({ required: "This field is required" })}
                                                type="number"
                                                name="homePickupNumberOfLessons"
                                                value={formData.homePickupNumberOfLessons}
                                                onChange={(e) => onInputChange(e)}
                                                defaultValue={formData.homePickupNumberOfLessons}
                                                placeholder="Enter a lesson number"
                                            />
                                            {errors.homePickupNumberOfLessons && <span className="invalid">{errors.homePickupNumberOfLessons.message}</span>}
                                        </FormGroup>
                                    </Col>
                                    <Col md="12">
                                        <span className='d-flex justify-content-center w-100 mt-4 mb-2' style={{fontWeight: 800, fontSize: "16px"}}> Perks</span>
                                    </Col>
                                    {formData.homePickupPerks.map((homePickupPerk, index)=> {
                                        return (
                                            <>
                                                <Row className='mt-2'>
                                                    <Col md="11">
                                                        <FormGroup>
                                                            <input
                                                                type="text"
                                                                id="homePickupPerks"
                                                                className="form-control"
                                                                name="homePickupPerks"
                                                                value={formData.homePickupPerks}
                                                                onChange={(e) => {
                                                                    const updatedPoints = [...formData.homePickupPerks];
                                                                    updatedPoints[index] = e.target.value;
                                                                    setFormData({ ...formData,  homePickupPerks: updatedPoints });
                                                                
                                                                }}
                                                                placeholder="Enter a perk"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    {(formData.homePickupPerks.length > 1 ) && (
                                                        <Col md="1" className="d-flex justify-content-center align-items-center p-0">
                                                            <div
                                                                color="primary"
                                                                className='cursor_pointer'
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    const fileteredHomePickupPerks = formData.homePickupPerks.filter((_, idx) => index !== idx);
                                                                    setFormData({...formData, homePickupPerks: fileteredHomePickupPerks });
                                                                }}
                                                                >
                                                                <Icon className="d-flex align-items-center" style={{ fontSize: '24px' }} name="cross" size="2x"></Icon>
                                                            </div>
                                                        </Col>
                                                    )}
                                                </Row>
                                            </>
                                        )
                                    })}
                                    <Col md="1" className="d-flex justify-content-center align-items-center mt-4 p-0">
                                        <div
                                            color="primary"
                                            className='cursor_pointer'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setFormData({ ...formData, homePickupPerks: [...formData.homePickupPerks, ""]})
                                            }}
                                        >
                                            <Icon className="d-flex align-items-center" style={{ fontSize: '24px' }} name="plus" size="3x"></Icon>
                                        </div>
                                    </Col>
                                </Col>
                                <Col size="12">
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button color="primary" size="md" type="submit" disabled={loading} onClick={handleUpdateRegularPackage}>
                                                {loading ? <Spinner /> : "Update regular package"}
                                            </Button>
                                        </li>
                                        <li>
                                            <div
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    setModal(false);
                                                }}
                                                className="link link-light cursor_pointer"
                                            >
                                                Cancel
                                            </div>
                                        </li>
                                    </ul>
                                </Col>
                            </Form>

                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default UpdateRegularPackageModal;
