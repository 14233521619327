import React, { useEffect } from 'react'
import { Badge, Col, Modal, ModalBody, Spinner } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { GetLessonSheetDetailThunk } from '../../../redux/StudentsSlice/InCarSheetSlice/GetLessonSheetDetailSlice';

const ShowRoadTestDetailModal = ({ modal, setModal, selectedLessonSheetId }) => {
    const dispatch = useDispatch();

    const { loading, lessonSheetDetailData } = useSelector((state)=> state.Students.InCarSheet.getLessonSheetDetail);

    useEffect(() => {
        dispatch(GetLessonSheetDetailThunk({ lessonSheetId: selectedLessonSheetId }))
    },[dispatch]);

    return (
        <React.Fragment>
            <Modal
                isOpen={modal.view}
                toggle={() => setModal({ view: false })}
                className="modal-dialog-centered"
                size="md"
            >
                <ModalBody>
                    {loading ?
                        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                            <Spinner />
                        </div>
                    : 
                        <div className="p-2">
                                <div className="row gy-4 mt-4 mb-5">
                                    <Col md="3">
                                        <h6>Road Test</h6> 
                                    </Col>
                                    <Col md="3">
                                        <Badge color={`${lessonSheetDetailData?.lessonSheet?.overallGrade === 1 ? 'success': "danger"}`}>{lessonSheetDetailData?.lessonSheet?.overallGrade === 1 ? "Passed": "Failed"}</Badge>
                                    </Col>
                                    <Col md="12">
                                        <h6>Notes</h6>
                                        <p>
                                            {lessonSheetDetailData?.lessonSheet?.notes}
                                        </p>
                                    </Col>
                            </div>
                        </div>
                    }
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default ShowRoadTestDetailModal;