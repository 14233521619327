import React, { useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import { Modal, ModalBody, FormGroup, Spinner } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  Button,
  RSelect,
} from "../../components/Component";
import { useDispatch } from "react-redux";
import { GetStudentPersonalDetailThunk } from "../../redux/StudentsSlice/StudentPersonalDetail";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  EditStudentProfileThunk,
  ResetEditStudentProfileData,
} from "../../redux/StudentsSlice/EditStudentProfileSlice";
import toast from "react-hot-toast";

const StudentProfile = ({ sm, updateSm, setProfileName, setProfileEmail }) => {
  const { studentId } = useParams();
  const dispatch = useDispatch();
  const { loading, studentPersonalDetail } = useSelector(
    (state) => state.Students.getStudentPersonalDetail
  );

  const {
    loading: EditStudentProfileLoading,
    success: EditStudentProfileSuccess,
  } = useSelector((state) => state.Students.editStudentProfile);

  const [modal, setModal] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    homePickup: true,
    postalCode: ""
  });

  useEffect(() => {
    if (studentPersonalDetail?.data) {
      setFormData({
        fullName: studentPersonalDetail?.data?.fullName,
        email: studentPersonalDetail?.data?.email,
        phoneNumber: studentPersonalDetail?.data?.phoneNumber,
        homePickup: studentPersonalDetail?.data?.homePickup,
        postalCode: studentPersonalDetail?.data?.postalCode
      });
      setProfileName(studentPersonalDetail?.data?.fullName);
      setProfileEmail(studentPersonalDetail?.data?.email);
    }
  }, [studentPersonalDetail]);

  useEffect(() => {
    dispatch(GetStudentPersonalDetailThunk({ studentId }));
  }, [dispatch, studentId]);

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleUpdateStudentProfile = () => {
    dispatch(
      EditStudentProfileThunk({
        fullName: formData.fullName,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        studentId,
        homePickup: formData.homePickup,
        postalCode: formData.postalCode
      })
    );
  };

  if (EditStudentProfileSuccess) {
    toast.success("Student profile has been updated successfully.");
    dispatch(ResetEditStudentProfileData());
    dispatch(GetStudentPersonalDetailThunk({ studentId }));
    setModal(false);
  }

  return (
    <React.Fragment>
      <Head title="User List - Profile"></Head>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Personal Information</BlockTitle>
            <BlockDes>
              <p>Basic info, like your name and address, that you use on Kruzee Platform.</p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button
              className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""}`}
              onClick={() => updateSm(!sm)}
            >
              <Icon name="menu-alt-r" />
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
            <Spinner />
          </div>
        ) : (
          <>
            <div className="nk-data data-list">
              <div className="data-head">
                <h6 className="overline-title"> Personal Detail</h6>
              </div>
              <div className="data-item" onClick={() => setModal(true)}>
                <div className="data-col">
                  <span className="data-label">Full Name</span>
                  <span className="data-value">{studentPersonalDetail?.data?.fullName}</span>
                </div>
                <div className="data-col data-col-end">
                  <span className="data-more">
                    <Icon name="forward-ios" />
                  </span>
                </div>
              </div>
              <div className="data-item" onClick={() => setModal(true)}>
                <div className="data-col">
                  <span className="data-label">Email</span>
                  <span className="data-value">{studentPersonalDetail?.data?.email}</span>
                </div>
                <div className="data-col data-col-end">
                  <span className="data-more">
                    <Icon name="forward-ios" />
                  </span>
                </div>
              </div>
              <div className="data-item" onClick={() => setModal(true)}>
                <div className="data-col">
                  <span className="data-label">Phone Number</span>
                  <span className="data-value ">{studentPersonalDetail?.data?.phoneNumber}</span>
                </div>
                <div className="data-col data-col-end">
                  <span className="data-more">
                    <Icon name="forward-ios" />
                  </span>
                </div>
              </div>
              <div className="data-item" onClick={() => setModal(true)}>
                <div className="data-col">
                  <span className="data-label">Home Pickup</span>
                  <span className="data-value ">{studentPersonalDetail?.data?.homePickup? "true": "false"}</span>
                </div>
                <div className="data-col data-col-end">
                  <span className="data-more">
                    <Icon name="forward-ios" />
                  </span>
                </div>
              </div>
              <div className="data-item" onClick={() => setModal(true)}>
                <div className="data-col">
                  <span className="data-label">Postal Code</span>
                  <span className="data-value">{studentPersonalDetail?.data?.postalCode}</span>
                </div>
                <div className="data-col data-col-end">
                  <span className="data-more">
                    <Icon name="forward-ios" />
                  </span>
                </div>
              </div>
              <div className="data-item">
                <div className="data-col">
                  <span className="data-label">SignUp Date</span>
                  <span className="data-value">{studentPersonalDetail?.data?.signupDate.split("T")[0]}</span>
                </div>
                <div className="data-col data-col-end">
                  <span className="data-more">
                    <Icon name="lock-alt" />
                  </span>
                </div>
              </div>
              <div className="data-item">
                <div className="data-col">
                  <span className="data-label">Address</span>
                  <span className="data-value">{studentPersonalDetail?.data?.address}</span>
                </div>
                <div className="data-col data-col-end">
                  <span className="data-more disable">
                    <Icon name="lock-alt" />
                  </span>
                </div>
              </div>

            </div>
          </>
        )}
      </Block>

      <Modal isOpen={modal} className="modal-dialog-centered" size="lg" toggle={() => setModal(false)}>
        <ModalBody>
          <div
            onClick={(ev) => {
              ev.preventDefault();
              setModal(false);
            }}
            className="close cursor_pointer"
          >
            <Icon name="cross-sm" />
          </div>
          <div className="p-2">
            <h5 className="title mb-5">Update Profile</h5>
            <div className="tab-content">
              <div className={`tab-pane active`} id="personal">
                <Row className="gy-4">
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label" htmlFor="fullName">
                        Full Name
                      </label>
                      <input
                        type="text"
                        id="fullName"
                        className="form-control"
                        name="fullName"
                        onChange={(e) => onInputChange(e)}
                        defaultValue={formData.fullName}
                        placeholder="Enter Full name"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label" htmlFor="email">
                        Email
                      </label>
                      <input
                        type="text"
                        id="email"
                        required
                        className="form-control"
                        name="email"
                        onChange={(e) => onInputChange(e)}
                        defaultValue={formData.email}
                        placeholder="Enter email"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label" htmlFor="phoneNumber">
                        Phone Number
                      </label>
                      <input
                        type="number"
                        id="phoneNumber"
                        className="form-control"
                        name="phoneNumber"
                        onChange={(e) => onInputChange(e)}
                        defaultValue={formData.phoneNumber}
                        placeholder="Phone Number"
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <label className="form-label" htmlFor="postalCode">
                        Postal Code
                      </label>
                      <input
                        type="text"
                        id="postalCode"
                        className="form-control"
                        name="postalCode"
                        onChange={(e) => onInputChange(e)}
                        defaultValue={formData.postalCode}
                        placeholder="Phone Number"
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Student Type</label>
                      <div className="form-control-wrap">
                        <RSelect
                          name="homePickup"
                          options={[{value: true ,label: "Home Pickup" }, {value: false ,label: "Central Pickup" }]}
                          defaultValue={{value: formData?.homePickup ,label: formData?.homePickup? "Home Pickup": "Central Pickup" }}
                          onChange={(e) => {
                            setFormData({ ...formData, homePickup: e.value });
                          }}
                        />
                      </div>
                    </FormGroup>
                  </Col>

                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button
                          disabled={EditStudentProfileLoading}
                          color="primary"
                          size="lg"
                          onClick={(ev) => {
                            ev.preventDefault();
                            handleUpdateStudentProfile();
                          }}
                        >
                          {EditStudentProfileLoading ? <Spinner /> : "Update Profile"}
                        </Button>
                      </li>
                      <li>
                        <div
                          onClick={(ev) => {
                            ev.preventDefault();
                            setModal(false);
                          }}
                          className="link link-light cursor_pointer"
                        >
                          Cancel
                        </div>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
export default StudentProfile;
