import { Field, Formik } from 'formik';
import React from 'react'
import { useState } from 'react';
import { Button, Col, Form, Label, Modal, ModalBody, Spinner } from 'reactstrap';
import { AddStudentRoadTestInCarSheetValues } from '../../../utils/FormValues';
import { AddStudentRoadTestInCarSheetValidationSchema } from '../../../utils/FormValidationSchema';
import { useDispatch } from 'react-redux';
import { AddLessonInCarSheetThunk, ResetAddLessonInCarSheetData } from '../../../redux/StudentsSlice/InCarSheetSlice/AddLessonInCarSheetSlice';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import toast from 'react-hot-toast';
import { GetAllInCarSheetLessonThunk } from '../../../redux/StudentsSlice/InCarSheetSlice/GetAllInCarSheetLessonSlice';

const RoadTestLessonEvaluationModal = ({ modal, setModal, selectedLessonId }) => {
    const dispatch = useDispatch();
    const { studentId } = useParams();
    const [isPass, setIsPass] = useState();
    const [notes, setNotes] = useState("");

    const { loading, success, error, errorMessage, AddLessonInCarSheetData} = useSelector((state)=> state.Students.InCarSheet.addLessonInCarSheet);
    const handleRoadTestEvaluation = (values) => {
        values.preventDefault();
        dispatch(AddLessonInCarSheetThunk({
            notes: notes,
            overallGrade: isPass ? 1 : 0,
            lessonId: selectedLessonId,
            studentId
        }))
    }

    if(success){
        toast.success("Road Test Sheet has been added successfully.");
        dispatch(GetAllInCarSheetLessonThunk({ studentId }));
        dispatch(ResetAddLessonInCarSheetData());
        setModal();
    }

    if(error){
        toast.error(errorMessage);
        dispatch(ResetAddLessonInCarSheetData());
        setModal();
    }

    return (
        <React.Fragment>
            <Modal
                isOpen={modal.addRoadTestEvaluation}
                toggle={() => setModal({ addRoadTestEvaluation: false })}
                className="modal-dialog-centered"
                size="md"
            >
                <ModalBody>
                {/* <div onClick={(ev) => { ev.preventDefault(); setModal({ addRoadTestEvaluation: false });}} className="close cursor_pointer">
                    <Icon name="cross-sm"></Icon>
                </div> */}
                <div className="p-2">
                    <h6 className="title d-flex justify-content-center">James</h6>
                    <div className="mt-4">
                    <Formik 
                        initialValues={AddStudentRoadTestInCarSheetValues}
                        validationSchema={AddStudentRoadTestInCarSheetValidationSchema}
                        enableReinitialize={true}
                        onSubmit={handleRoadTestEvaluation}
                    >

                        <Form className="row gy-4 mt-4 mb-5">
                            <Col md="12">
                                <p>Did the Student pass or fail the road test?</p>
                            </Col>
                            <Col md="4">
                                <div className='d-flex justify-content-between'>
                                    <div className="custom-control custom-control-sm custom-radio">
                                        <input
                                            type="radio"
                                            className="custom-control-input"
                                            name="isPass"
                                            onChange={(e)=> setIsPass(true)}
                                            id="radio-bl"
                                        />
                                        <label className="custom-control-label" htmlFor="radio-bl">
                                            Pass
                                        </label>
                                    </div>
                                    <div className="custom-control custom-control-sm custom-radio">
                                        <input
                                            type="radio"
                                            className="custom-control-input"
                                            onChange={(e) => setIsPass(false)}
                                            name="isPass"
                                            id="radio-b2"
                                        />
                                        <label className="custom-control-label" htmlFor="radio-b2">
                                            Fail
                                        </label>
                                    </div>
                                </div>

                            </Col>
                            <Col md="12">
                                <Label className="form-label" htmlFor="fv-message">
                                    Note
                                </Label>
                                <textarea
                                    //   ref={register({
                                    //     required: true,
                                    //   })}
                                    type="textarea"
                                    className="form-control form-control-sm"
                                    id="fv-message"
                                    value={notes}
                                    onChange={(e)=> setNotes(e.target.value)}
                                    name="message"
                                    placeholder="Write your message"
                                />
                            </Col>
                            <br />
                            <Col size="12">
                                <ul className="d-flex justify-content-between flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                    <Button disabled={loading} onClick={(e)=> handleRoadTestEvaluation(e)} color="primary" size="md" type="submit">
                                        {loading ? <Spinner size="sm" /> : "Saved"}
                                    </Button>
                                </li>
                                <li className="mt-2 mr-2">
                                    <div onClick={(ev) => { ev.preventDefault(); setModal({ addRoadTestEvaluation: false });}} className="link link-light cursor_pointer">
                                        Cancel
                                    </div>
                                </li>
                                </ul>
                            </Col>
                        </Form>
                    </Formik>
                    </div>
                </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default RoadTestLessonEvaluationModal;