import { Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, FormGroup, Row, Spinner } from 'reactstrap';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from 'react-redux';
import Dropzone from 'react-dropzone';
import toast from 'react-hot-toast';
import moment from 'moment';
import axios from 'axios';

import { InitiateStudentNewInCarSheetThunk, ResetInitiateStudentNewInCarSheetData } from '../../../redux/StudentsSlice/InCarSheetSlice/InitiateStudentNewInCarSheetSlice';
import { BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, RSelect } from '../../../components/Component';
import { GetAllInCarSheetLessonThunk } from '../../../redux/StudentsSlice/InCarSheetSlice/GetAllInCarSheetLessonSlice';
import { AddStudentInCarReportValidationSchema } from '../../../utils/FormValidationSchema';
import { AddStudentInCarReportValues } from '../../../utils/FormValues';
import { ApiEndpoints } from '../../../utils/ApiEndpoints';
import Head from '../../../layout/head/Head';

const StudentInCarReport = ({page, setPage}) => {
    const dispatch = useDispatch();
    const { studentId } = useParams();
    const [formData, setFormData] = useState({
        fullName: "",
        address: "",
        issueDate: new Date(),
        dateOfBirth: new Date(),
        driversLicenseNumber: "",
        licenseType: "",
        region: "ontario",
        licenseImage:"",
        date: new Date()
    });

    const [region, setRegion] = useState([
        {
            label: "Ontario",
            value:"ontario"
        },
        {
            label: "British Colombia",
            value: "british-colombia"
        }
    ]);
    const [licenseType, setLicenseType] = useState([
        {
            label: "G",
            value: "G"
        },
        {
            label: "G2",
            value: "G2"
        }
    ])

    const [licenseImage, setLicenseImage] = useState([]);
    const [LogoImageLoading, setLogoImageLoading] = useState(false);

    const {loading, success, error, errorMessage} = useSelector((state)=> state.Students.InCarSheet.initiateStudentInCarReport);


    const onInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    
    const handleDriverLisenceUploader = async (acceptedFiles, set) => {
        const formDt = new FormData();
        if (Number((acceptedFiles[0].size) / 1024) > 1000) {
            toast.error("Image size exceeding 1MB, please upload a smaller image");
            } else {
                set(
                    acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                    )
                );
                setLogoImageLoading(true);
                formDt.append("image", acceptedFiles[0]);
            try {
                const response = await axios.post(`${ApiEndpoints.UploadImages}/studentLicenses`, formDt, {
                headers: { "Content-Type": "multipart/form-data" },
                });
                setFormData({ ...formData, licenseImage: response.data.imageName });
                setLogoImageLoading(false);
            } catch (error) {
                setLogoImageLoading(false);
            }
        }
    };

    const handleStudentInCarReport = (e) => {
        e.preventDefault();
        dispatch(InitiateStudentNewInCarSheetThunk({
            studentId: studentId,
            fullName: formData.fullName,
            address: formData.address,
            driversLicenseNumber: formData.driversLicenseNumber,
            issueDate: moment(formData?.issueDate).startOf("D").toISOString(),
            dateOfBirth: moment(formData?.dateOfBirth).startOf("D").toISOString(),
            licenseType: formData.licenseType,
            licenseImage: formData.licenseImage,
            region: formData.region
        }))
    }

    useEffect(()=>{
        setLicenseType(
            formData?.region === "ontario" ? [
                {
                    label: "G1",
                    value: "G1"
                },
                {
                    label: "G2",
                    value: "G2"
                },
                {
                    label: "G",
                    value: "G"
                }
            ] : [
                {
                    label: "Class 5",
                    value: "class5"
                },
                {
                    label: "Class 7",
                    value: "class7"
                }
            ]

        )
    },[formData?.region]);

    if(success){
        toast.success("In-Report has been initiated successfully.");
        dispatch(GetAllInCarSheetLessonThunk({ studentId }));
        dispatch(ResetInitiateStudentNewInCarSheetData());
        setPage({inCarReport: false, view: true});
    }

    if(error){
        toast.error(errorMessage);
        dispatch(ResetInitiateStudentNewInCarSheetData());
        setPage({inCarReport: false, view: true});
    }

    return (
        <React.Fragment>
            <Head title="Student In-Car Sheet | Admin Portal" />
            <Row className="d-flex justify-content-between ">
                <Col sm="12">
                    <div className='d-flex justify-content-between'>
                        <div className=' mb-5'>
                            <Button className='btn-dim' color="primary" onClick={()=> setPage({showInCarReport: false, view: true })}> 
                                <Icon name="back-arrow-fill"/>
                                {" "} Back
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>

            <BlockHead size="sm">
                <BlockBetween>
                    <BlockHeadContent>
                        <BlockTitle page>
                            Initiate In-Car Report
                        </BlockTitle>
                    </BlockHeadContent>
                </BlockBetween>
            </BlockHead>
            <div className="mt-4">
                <Formik
                    initialValues={AddStudentInCarReportValues}
                    validationSchema={AddStudentInCarReportValidationSchema}
                    enableReinitialize={true}
                    // onSubmit={handleStudentInCarReport}
                >
                {({ errors, touched }) => (
                    <Form className="row gy-4 mt-4">
                        <Col md="6">
                            <FormGroup>
                            <label className="form-label" htmlFor="fullName">
                                Full Name
                            </label>
                            <input
                                type="text"
                                id="fullName"
                                required
                                className="form-control"
                                name="fullName"
                                onChange={(e) => onInputChange(e)}
                                defaultValue={formData.fullName}
                                placeholder="Enter Full name"
                            />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                            <label className="form-label" htmlFor="address">
                                Address
                            </label>
                            <input
                                type="text"
                                id="address"
                                required
                                className="form-control"
                                name="address"
                                onChange={(e) => onInputChange(e)}
                                defaultValue={formData.address}
                                placeholder="Enter Address"
                            />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                            <label className="form-label" htmlFor="driversLicenseNumber">
                                Driver License Number
                            </label>
                            <input
                                type="text"
                                id="driversLicenseNumber"
                                className="form-control"
                                required
                                name="driversLicenseNumber"
                                onChange={(e) => onInputChange(e)}
                                defaultValue={formData.driversLicenseNumber}
                                placeholder="Enter Driver License Number"
                            />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <label className="form-label">Date of Birth</label>
                                <div className="form-control-wrap">
                                    <DatePicker
                                        showYearDropdown
                                        dropdownMode="select"
                                        selected={formData.dateOfBirth}
                                        onChange={(date) => setFormData({ ...formData, dateOfBirth: date })}
                                        className="form-control date-picker"
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <label className="form-label">Issue Date</label>
                                <div className="form-control-wrap">
                                    <DatePicker
                                        selected={formData.issueDate}
                                        onChange={(date) => setFormData({ ...formData, issueDate: date })}
                                        className="form-control date-picker"
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <label className="form-label">Region</label>
                                <div className="form-control-wrap">
                                    <RSelect
                                        name="licenseType"
                                        options={region}
                                        defaultValue={region[0]}
                                        onChange={(e) => {
                                            setFormData({ ...formData, region: e.value });
                                        }}
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                        <Col md="12">
                            <FormGroup>
                                <label className="form-label">License Type</label>
                                <div className="form-control-wrap">
                                    <RSelect
                                        name="licenseType"
                                        options={licenseType}
                                        // defaultValue={licenseType[0]}
                                        onChange={(e) => {
                                            setFormData({ ...formData, licenseType: e.value });
                                        }}
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                        <Col sm="12">
                            <label className="form-label">Licence Image</label>
                            <Dropzone onDrop={(acceptedFiles) => handleDriverLisenceUploader(acceptedFiles, setLicenseImage)} maxFiles={1}>
                                {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div {...getRootProps()} className="dropzone upload-zone dz-clickable">
                                    <input {...getInputProps()} />
                                    {licenseImage.length === 0 && (
                                        <div className="dz-message">
                                            <span className="dz-message-text">Drag and drop file</span>
                                            <span className="dz-message-or">or</span>
                                            <Button color="primary" onClick={(e) => e.preventDefault()} >SELECT</Button>
                                        </div>
                                    )}
                                    {licenseImage.map((file) => (
                                        <div
                                            key={file.name}
                                            className="dz-preview dz-processing dz-image-preview dz-error dz-complete"
                                        >
                                            <div className="dz-image">
                                                <img src={file.preview} alt="preview" />
                                            </div>
                                        </div>
                                    ))}
                                    </div>
                                </section>
                                )}
                            </Dropzone>
                        </Col>
                        {/* <Col md="6">
                            <FormGroup>
                            <label className="form-label">Instructor</label>
                            <div className="form-control-wrap">
                                <RSelect
                                name="instructorId"
                                options={instructorsList?.instructors?.map((instructor) => {
                                    return { value: instructor?._id, label: instructor?.fullName };
                                })}
                                defaultValue={{
                                    value:
                                    Object.keys(instructorsList).length !== 0 ? instructorsList?.instructors[0]?._id : "",
                                    label:
                                    Object.keys(instructorsList).length !== 0
                                        ? instructorsList?.instructors[0]?.fullName
                                        : "",
                                }}
                                onChange={(e) => {
                                    setFormData({ ...formData, instructorId: e.value });
                                }}
                                />
                            </div>
                            </FormGroup>
                        </Col> */}
                        <Col size="12">
                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                            <li>
                                <Button disabled={loading} color="primary" onClick={handleStudentInCarReport} size="md" type="submit">
                                {loading ? <Spinner /> : "Initiate In-Car Report"}
                                </Button>
                            </li>
                            <li>
                                <div
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                        setPage({ addStudent: false });
                                    }}
                                    className="link link-light cursor_pointer"
                                >
                                    Cancel
                                </div>
                            </li>
                            </ul>
                        </Col>
                    </Form>
                )}
                </Formik>
            </div>

        </React.Fragment>
    )
}

export default StudentInCarReport;