import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, Spinner, UncontrolledDropdown } from "reactstrap";
import ReactPaginate from "react-paginate";

import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, PreviewAltCard } from "../../components/Component";
import { GetStudentNotificationThunk } from "../../redux/ConfigurationSlice/GetStudentNotificationSlice";
import { GetAllRegionsThunk } from "../../redux/ConfigurationSlice/GetAllRegionsSlice.js";
import NotificationModal from "./NotificationModal.js";
import AddRegionModal from "./AddRegionModal.js";
import UpdateRegionModal from "./UpdateRegionModal.js";

const Configuration = () => {
    const dispatch = useDispatch();

    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState("");
    const [selectedLicenceType, setSelectedLicenceType] = useState([]);
    const [selectedRegionData, setSelectedRegionData] = useState([]);
    const [notificationData, setNotificationData] = useState();
    const [modal, setModal] = useState({
        studentNotification: false,
        addRegion: false,
        updateRegion: false
    })

    const { loading: getAllRegionsLoading, getAllRegionsData } = useSelector((state) => state.configuration.getAllRegions)
    const { loading, getStudentNotificationData } = useSelector((state) => state.configuration.getStudentNotification)

    const formsPerPage = 10;
    const [allforms, setAllForms] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const pagesVisited = pageNumber * formsPerPage;
    const pageCount = Math.ceil(
        allforms?.filter((listing) => {
            if (onSearchText === "") {
                return listing;
            } else if (
                listing?.fullName?.toLowerCase().includes(onSearchText.toLowerCase()) ||
                listing?.creationDate?.toLowerCase().includes(onSearchText.toLowerCase())
            ) {
                return listing;
            }
            return false;
        }).length / formsPerPage
    );

    const displayListing = allforms
        .filter((listing) => {
            if (onSearchText === "") {
                return listing;
            } else if (
                listing?.fullName?.toLowerCase().includes(onSearchText.toLowerCase()) ||
                listing?.creationDate?.toLowerCase().includes(onSearchText.toLowerCase())
            ) {
                return listing;
            }
            return false;
        })
        .slice(pagesVisited, pagesVisited + formsPerPage);

    useEffect(() => {
        if(getAllRegionsData) 
            setAllForms(getAllRegionsData);
    }, [getAllRegionsData]);

    useEffect(() => {
        setPageNumber(0);
    }, [onSearchText]);
    useEffect(() => {
        dispatch(GetStudentNotificationThunk());
        dispatch(GetAllRegionsThunk());
    }, [dispatch]);

    const paginate = ({ selected }) => setPageNumber(selected);

    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    };
    const toggle = () => setonSearch(!onSearch);

    const handleNotificationModal = ({ field, value }) => {
        setNotificationData({ field, value });
        setModal({ studentNotification: true });
    }

    return (
        <React.Fragment>
            <Head title="Configuration - Admin portal"></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>Admin Configuration</BlockTitle>
                            <BlockDes className="text-soft">
                            </BlockDes>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                <Block>
                    {loading ?
                        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                            <Spinner />
                        </div>
                        : (
                            <>
                                <Card className="card-bordered card-stretch">
                                    <div className="card-inner-group">
                                        <div className="card-inner p-0">
                                            <table className="table table-tranx">
                                                <tbody>
                                                    <tr className="tb-tnx-item">
                                                        <td className="tb-tnx-info">
                                                            <div className="tb-tnx-desc">
                                                                <span className="title">Student Lesson Reminder Notification</span>
                                                            </div>
                                                        </td>
                                                        <td className="tb-tnx-action">
                                                            <span className={`ml-2 badge badge-dot `} >
                                                                <div className="custom-control custom-switch">
                                                                    <input type="checkbox" className="custom-control-input form-control" checked={getStudentNotificationData.twilioStudentNotificationsEnabled} onClick={() => handleNotificationModal({field: "twilioStudentNotificationsEnabled", value: !getStudentNotificationData.twilioStudentNotificationsEnabled})} id="twilioStudentNotificationsEnabled" />
                                                                    <label className="custom-control-label" htmlFor="twilioStudentNotificationsEnabled"> </label>
                                                                </div> 
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Card>
                                <Card className="card-bordered card-stretch">
                                    <div className="card-inner-group">
                                        <div className="card-inner p-0">
                                            <table className="table table-tranx">
                                                <tbody>
                                                    <tr className="tb-tnx-item">
                                                        <td className="tb-tnx-info">
                                                            <div className="tb-tnx-desc">
                                                                <span className="title">Instructor Reminder Notification</span>
                                                            </div>
                                                        </td>
                                                        <td className="tb-tnx-action">
                                                            <span className={`ml-2 badge badge-dot `} >
                                                                <div className="custom-control custom-switch">
                                                                    <input type="checkbox" className="custom-control-input form-control" checked={getStudentNotificationData.instructorNotificationsEnabled} onClick={() => handleNotificationModal({field: "instructorNotificationsEnabled", value: !getStudentNotificationData.instructorNotificationsEnabled})} id="instructorNotificationsEnabled" />
                                                                    <label className="custom-control-label" htmlFor="instructorNotificationsEnabled"> </label>
                                                                </div> 
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Card>
                            </>
                        )
                    }
                </Block>
                <Block className="mt-4">
                    <BlockHead size="sm">
                        <BlockBetween>
                        <BlockHeadContent>
                                <BlockTitle page>Regions</BlockTitle>
                                <BlockDes className="text-soft">
                                </BlockDes>
                            </BlockHeadContent>
                            <BlockHeadContent>
                            <ul className="nk-block-tools-opt">
                                <li>
                                    <Button color="primary" onClick={() => setModal({addRegion: true})}>
                                    <Icon name="plus" />
                                    <span>Add Region</span>
                                    </Button>
                                </li>
                            </ul>
                            </BlockHeadContent>
                        </BlockBetween>
                    </BlockHead>

                    {getAllRegionsLoading ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                            <Spinner />
                        </div>
                    ) : (
                        <Card className="card-bordered card-stretch">
                            <div className="card-inner p-0">
                                <table className="table table-tranx">
                                    <thead>
                                        <tr className="tb-tnx-head">
                                            <th className="tb-tnx-id">
                                                <span className="">Region Name</span>
                                            </th>
                                            <th className="tb-tnx-id">
                                                <span className="">Tax Percentage</span>
                                            </th>
                                            <th className="tb-tnx-info">
                                                <span className="tb-tnx-desc d-none d-sm-inline-block">
                                                    <span>Road Test Price</span>
                                                </span>
                                                <span className="tb-tnx-date d-md-inline-block d-none">
                                                    <span className="d-none d-md-block">
                                                        <span>Theory Lesson Price</span>
                                                        {/* <span>Popular</span> */}
                                                    </span>
                                                </span>
                                            </th>
                                            <th className="tb-tnx-action">
                                                <span>Action</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {displayListing?.length > 0
                                            ? displayListing?.map((regionData, index) => {
                                                return (
                                                    <tr key={regionData?._id} className="tb-tnx-item">
                                                        <td className="tb-tnx-id">
                                                            <div>
                                                                <span>{regionData?.region}</span>
                                                            </div>
                                                        </td>
                                                        <td className="tb-tnx-id">
                                                            <div>
                                                                <span>{regionData?.taxPercentage}</span>
                                                            </div>
                                                        </td>
                                                        <td className="tb-tnx-info">
                                                            <div className="tb-tnx-desc">
                                                                <span className={`title tb-tnx-status`}>{regionData?.roadTestPrice}</span>
                                                            </div>
                                                            <div className="tb-tnx-date">
                                                                <span className={`date tb-tnx-status`}>{regionData?.theoryLessonPrice}</span>
                                                                {/* <span className={`date tb-tnx-status`}>{packageDetail?.popular ? "Yes" : "No"}</span> */}
                                                            </div>
                                                        </td>
                                                        <td className="tb-tnx-action">
                                                            <UncontrolledDropdown>
                                                            <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                                                                <Icon name="more-h"></Icon>
                                                            </DropdownToggle>
                                                            <DropdownMenu right>
                                                                <ul className="link-list-plain">
                                                                <li>
                                                                        <DropdownItem onClick={(ev) => { ev.preventDefault(); setModal({ viewRegion: true }); }}>
                                                                            View Detail
                                                                        </DropdownItem>
                                                                    </li>
                                                                    <li>
                                                                        <DropdownItem onClick={(ev) => { ev.preventDefault(); setModal({ updateRegion: true }); setSelectedRegionData(regionData) }}>
                                                                            Update
                                                                        </DropdownItem>
                                                                    </li>
                                                                </ul>
                                                            </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                            : null}
                                                        <div className="card-inner">
                                        {displayListing?.length > 0 ? (
                                            <ReactPaginate
                                                breakLabel="..."
                                                breakClassName="pagination-btns"
                                                onPageChange={(number) => paginate(number)}
                                                pageRangeDisplayed={3}
                                                pageCount={pageCount}
                                                previousLabel="<<  Prev "
                                                renderOnZeroPageCount={null}
                                                nextLabel="Next >>"
                                                previousClassName="pagination-next"
                                                nextClassName="pagination-next"
                                                activeClassName="pagination-active"
                                                pageClassName="pagination mt-0"
                                                pageLinkClassName="pagination-btns"
                                                containerClassName="pagination justify-content-start"
                                            />
                                            ) : (
                                                <div className="text-center">
                                                    <span className="text-silent">No data found</span>
                                                </div>
                                            )}
                                        </div>

                                    </tbody>
                                </table>
                            </div>
                        </Card>

                        // <div className="row">
                        //     {getAllRegionsData?.map((regionData, index)=> {
                        //         return (
                        //             <div className="col-lg-4 mt-3">
                        //                     <PreviewAltCard>
                        //                     <div className="team">
                        //                         <div className="user-card user-card-s2">
                        //                             <Row className="d-flex align-items-center w-100">
                        //                                 <Col md="9">
                        //                                     <h6>{regionData?.region} </h6>
                        //                                 </Col>
                        //                                 <Col md="3">
                        //                                     <Button color="primary" onClick={()=> setModal({ updateRegion: true})}>
                        //                                         Update
                        //                                     </Button>
                        //                                 </Col>
                                                        
                        //                             </Row>
                        //                         </div>
                        //                         <ul className="team-statistics">
                        //                             <li>
                        //                                 <span> <span style={{fontWeight: "bold"}}>Tax Percentage </span>  {regionData?.taxPercentage}</span>
                        //                             </li>
                        //                             <li>
                        //                                 <span> <span style={{fontWeight: "bold"}}> Road Test Price </span>  {regionData?.roadTestPrice}</span>
                        //                             </li>
                        //                             <li>
                        //                                 <span> <span style={{fontWeight: "bold"}}> Theory Lesson Price </span>  {regionData?.theoryLessonPrice}</span>
                        //                             </li>

                        //                         </ul>
                        //                         <ul className="team-statistics">
                        //                             {regionData?.licenseTypes?.map((licencetype, index)=> {
                        //                                 return (
                        //                                     <li>
                        //                                         <span> <span style={{fontWeight: "bold"}}>{index + 1}. </span>  {licencetype?.label}</span>
                        //                                     </li>
                        //                                 )
                        //                             })}
                        //                         </ul>
                        //                     </div>
                        //                     </PreviewAltCard>
                        //             </div>
                        //         )
                        //     })}
                        // </div>
                    )}
                </Block>
            </Content>
            {modal.studentNotification && (
                <NotificationModal modal={modal} setModal={()=> setModal({studentNotification: false})} notificationData={notificationData} />
            )}
            {modal.addRegion && (
                <AddRegionModal modal={modal} setModal={()=> setModal({addRegion: false})} notificationData={notificationData} />
            )}
            {modal.updateRegion && <UpdateRegionModal modal={modal} setModal={()=> setModal({ updateRegion: false })} selectedRegionData={selectedRegionData} /> }
        </React.Fragment>
    )
}

export default Configuration;
