import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormGroup, Modal, ModalBody, Row, Spinner } from 'reactstrap';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router";
import toast from 'react-hot-toast';

import { ConvertRoadTestToLessonThunk, ResetConvertRoadTestToLessonData } from '../../../redux/LessonsSlice/ConvertRoadTestToLessonSlice';
import { GetStudentRoadTestThunk } from '../../../redux/StudentsSlice/GetStudentRoadTestSlice';
import { Icon, RSelect } from '../../../components/Component';

export const NumberOfLessonsOptions = [
    { value: 1, label: "One Lesson" },
    { value: 2, label: "Two Lessons" },
    { value: 3, label: "Three Lessons" },
    { value: 4, label: "Four Lessons" },
    { value: 5, label: "Five Lessons" },
    { value: 6, label: "Six Lessons" },
    { value: 7, label: "Seven Lessons" },
    { value: 8, label: "Eight Lessons" },
    { value: 9, label: "Nine Lessons" },
    { value: 10, label: "Ten Lessons" },
    { value: 11, label: "Eleven Lessons" },
    { value: 12, label: "Twelve Lessons" },
    { value: 13, label: "Thirteen Lessons" },
    { value: 14, label: "Fourteen Lessons" },
    { value: 15, label: "Fifteen Lessons" },
];

const ConvertRoadTestToLessonsModal = ({ modal, setModal, roadTestDetail }) => {
    const dispatch = useDispatch();
    const { studentId } = useParams();


    const { loading: packageLoading, packagesData } = useSelector((state) => state.Students.getPackages)
    const { loading, success, error, errorMessage } = useSelector((state)=> state.lessons.convertRoadTestToLesson)
    const {
        loading: studentInstructorLoading,
        studentInstructorsData,
    } = useSelector((state) => state.Students.getStudentInstructors);
    const { studentPersonalDetail } = useSelector((state) => state.Students.getStudentPersonalDetail);

    const [instructorList, setInstructorList] = useState([]);
    const [numberOfLessons, setNumberOfLessons] = useState(4);
    const [selectedInstructor, setSelectedInstructor] = useState();
    const [expiryDate, setExpiryDate] = useState();
    const [packageName, setPackageName] = useState();
    const [homePickup, setHomePickup] = useState();

    useEffect(() => {
        const list = studentInstructorsData?.instructors?.map((instructor) => {
            return { label: instructor?.fullName, value: instructor?._id };
        });
        setInstructorList(list);
    }, [studentInstructorsData]);

    useEffect(()=>{
        if(packagesData?.packages)
            setPackageName(packagesData?.packages[0]?.name)
        if(studentPersonalDetail?.data) {

        }

    }, [packagesData, studentPersonalDetail])

    const handleConvertRoadTestToLesson = (e) => {
        e.preventDefault();
        dispatch(ConvertRoadTestToLessonThunk({ studentId, lessons: numberOfLessons, instructorId: selectedInstructor, packageName, expiryDate, roadTestId: roadTestDetail?._id, homePickup  }))
    }

    if(success) {
        toast.success("Road test has been converted into lessons successfully.");
        dispatch(ResetConvertRoadTestToLessonData());
        dispatch(GetStudentRoadTestThunk({ studentId }));
        setModal();
    }

    if(error) {
        toast.error(errorMessage);
        dispatch(ResetConvertRoadTestToLessonData());
        dispatch(GetStudentRoadTestThunk({ studentId }));
        setModal();
    }

    return (
        <React.Fragment>
            <Modal
                isOpen={modal.convertRoadTestToLesson}
                toggle={() => setModal({ convertRoadTestToLesson: false })}
                className="modal-dialog-centered"
                size="lg"
            >
                <ModalBody>
                    <div
                        onClick={(ev) => {
                            ev.preventDefault();
                            setModal({ convertRoadTestToLesson: false });
                        }}
                        className="close cursor_pointer"
                    >
                    <Icon name="cross-sm"></Icon>
                    </div>
                    <div className="p-2">
                        <h5 className="title">Convert Road Test To Lesson</h5>
                        <div className="mt-4">
                            {studentInstructorLoading || packageLoading? (
                                <div className="d-flex justify-content-center align-items-center" style={{ height: "40vh" }}>
                                    <Spinner />
                                </div>
                            ) : (
                            <Form className="row gy-4 mt-4">
                                <Col md="12">
                                    <FormGroup>
                                        <label className="form-label">Student Signup Date</label>
                                        <div className="form-control-wrap">
                                        <span>{ moment(studentPersonalDetail?.data?.signupDate).format("YYYY-MM-DD")}</span>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Select number of lessons</label>
                                        <div className="form-control-wrap">
                                        <RSelect
                                            options={NumberOfLessonsOptions}
                                            defaultValue={{label: studentPersonalDetail?.data?.homePickup ? "Two Lessons" : "Four Lessons" , value: studentPersonalDetail?.data?.homePickup ? 2 : 4}}
                                            placeholder="choose number of lessons"
                                            onChange={(e) => setNumberOfLessons(e.value)}
                                        />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                    <label className="form-label">Home Pickup Lesson</label>
                                    <div className="form-control-wrap">
                                        <RSelect
                                        name="homePickup"
                                        options={[{value: true, label: "True" }, {value: false, label: "False" }]}
                                        defaultValue={{value: studentPersonalDetail?.data?.homePickup ,label: studentPersonalDetail?.data?.homePickup? "True": "False" }}
                                        onChange={(e) => setHomePickup(e.value)}
                                        />
                                    </div>
                                    </FormGroup>
                                </Col>

                                <Col md="12">
                                    <FormGroup>
                                        <label className="form-label">Package Name</label>
                                        <div className="form-control-wrap">
                                            <RSelect
                                                name="packageName"
                                                options={
                                                    packagesData?.packages?.map((packageDetail)=> (
                                                        {value: packageDetail, label: `${packageDetail?.name} (${packageDetail?.region === "ont" ? "Ontario" : "British Columbia"})`}
                                                    ))
                                                }
                                                defaultValue={{value: `${packagesData?.packages[0]}`, label: `Hourly Driving Lessons (${studentPersonalDetail?.data?.packageRegion === "ont" ? "Ontario" : studentPersonalDetail?.data?.packageRegion === "bc" ? "Birtish Columbia" : studentPersonalDetail?.data?.packageRegion === "ny" ? "New York" : studentPersonalDetail?.data?.packageRegion === "alb" && "Alberta" })` }}
                                                onChange={(e) => 
                                                    setPackageName(e.value.name)
                                                }
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm="12">
                                    <FormGroup>
                                        <label className="form-label">Expiry Date</label>
                                        <Row className="gx-2">
                                        <div className="w-55">
                                            <div className="form-control-wrap">
                                            <DatePicker
                                                selected={expiryDate}
                                                onChange={(date) => setExpiryDate(date)}
                                                className="form-control date-picker"
                                            />
                                            </div>
                                        </div>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup>
                                        <label className="form-label">Choose Instructor</label>
                                        <div className="form-control-wrap">
                                            <RSelect
                                                options={instructorList}
                                                name="SelectsInstructor"
                                                placeholder="Choose Instructor"
                                                onChange={(e) => setSelectedInstructor(e.value)}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col size="12">
                                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                    <li>
                                        <Button color="primary" size="md" type="submit" disabled={loading} onClick={(e)=> handleConvertRoadTestToLesson(e)}>
                                            {loading ? <Spinner /> : "Convert Road Test To Lesson"}
                                        </Button>
                                    </li>
                                    <li>
                                        <div
                                            onClick={(ev) => {
                                            ev.preventDefault();
                                            setModal({ convertRoadTestToLesson: false });
                                            }}
                                            className="link link-light cursor_pointer"
                                        >
                                            Cancel
                                        </div>
                                    </li>
                                </ul>
                                </Col>
                            </Form>
                            )}
                        </div>
                    </div>
                </ModalBody>
            </Modal>

        </React.Fragment>
    )
}

export default ConvertRoadTestToLessonsModal;
