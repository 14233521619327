import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormGroup, Modal, ModalBody, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { Icon, RSelect } from '../../../components/Component';
import { regionalRoadTestValue, regionalTheoryLessonValue } from '../../../utils/Utils';
import { GetInstallmentPackagesThunk } from '../../../redux/PackagesSlice/GetInstallmentPackagesSlice';
import { GetAllRegionsThunk } from '../../../redux/ConfigurationSlice/GetAllRegionsSlice';
import { ResetUpdateInstallmentPackageData, UpdateInstallmentPackageThunk } from '../../../redux/PackagesSlice/UpdateInstallmentPackageSlice';

const UpdateInstallmentPackageModal = ({ modal, setModal, selectedPackageData }) => {
    const dispatch = useDispatch();

    const { loading, success, error, errorMessage} = useSelector((state) => state.packages.updateInstallmentPackage)
    const {
        loading: getAllRegionLoading,
        getAllRegionsData
    } = useSelector((state)=> state.configuration.getAllRegions)
    
    const [invoices, setInvoices] = useState([
        {
            invoiceNumber: 1,
            lessonHours: 0,
            amount: 0,
            roadTest: false,
            theory: false,
            dueInDays: 30
        }
    ])
    const [formData, setFormData] = useState({
        packageName: "",
        region: "ont",
        taxPercentage: 0,
        hourlyPrice: 0,
    });

    useEffect(()=>{
        dispatch(GetAllRegionsThunk());
    }, [dispatch]);

    useEffect(()=>{
        setFormData({
            packageName: selectedPackageData?.name,
            region: selectedPackageData?.region,
            hourlyPrice: selectedPackageData?.hourlyPrice,
            taxPercentage: selectedPackageData?.taxPercentage
        })
        selectedPackageData?.invoices &&
            setInvoices(selectedPackageData?.invoices)
    }, [selectedPackageData]);

    const handleInvoicesTax = (value) => {
        const regionRoadTestData = regionalRoadTestValue.find(item => item.name === formData.region);
        const regionRoadTestValue =  Number(regionRoadTestData.value) + (Number(regionRoadTestData.value) * (Number(formData.taxPercentage) / 100))

        const regionTheoryData = regionalTheoryLessonValue.find(item => item.name === formData.region);
        const regionTheoryValue =  Number(regionTheoryData.value) + (Number(regionTheoryData.value) * (Number(formData.taxPercentage) / 100))

        setInvoices(prevInvoices =>
            prevInvoices.map((inv, i) => {
                if(inv.roadTest) {
                    return { ...inv, amount: (Number(regionRoadTestValue) +  Number(inv.lessonHours) * (Number(formData.hourlyPrice) + (Number(formData.hourlyPrice) * (Number(value) / 100)))).toFixed(2) } 
                } else if(inv.theory) {
                    return { ...inv, amount: (Number(regionTheoryValue) + Number(inv.lessonHours) * (Number(formData.hourlyPrice) + (Number(formData.hourlyPrice) * (Number(value) / 100)))).toFixed(2) } 
                } else {
                    return { ...inv, amount: (Number(inv.lessonHours) * (Number(formData.hourlyPrice) + (Number(formData.hourlyPrice) * (Number(value) / 100)))).toFixed(2) } 
                }
            })
        );

        setFormData({ ...formData, taxPercentage: Number(value) });
    }

    const handleHourlyPrice = (e) => {
        e.preventDefault();

        const regionRoadTestData = regionalRoadTestValue.find(item => item.name === formData.region);
        const regionRoadTestValue =  Number(regionRoadTestData.value) + (Number(regionRoadTestData.value) * (Number(formData.taxPercentage) / 100))

        const regionTheoryData = regionalTheoryLessonValue.find(item => item.name === formData.region);
        const regionTheoryValue =  Number(regionTheoryData.value) + (Number(regionTheoryData.value) * (Number(formData.taxPercentage) / 100))
        setInvoices(prevInvoices =>
            prevInvoices.map((inv, i) => {
                if(inv.roadTest) {
                    return { ...inv, amount: (Number(regionRoadTestValue) +  Number(inv.lessonHours) * (Number(e.target.value) + (Number(e.target.value) * (Number(formData.taxPercentage) / 100)))).toFixed(2) } 
                } else if(inv.theory) {
                    return { ...inv, amount: (Number(regionTheoryValue) + Number(inv.lessonHours) * (Number(e.target.value) + (Number(e.target.value) * (Number(formData.taxPercentage) / 100)))).toFixed(2) } 
                } else {
                    return { ...inv, amount: (Number(inv.lessonHours) * (Number(e.target.value) + (Number(e.target.value) * (Number(formData.taxPercentage) / 100)))).toFixed(2) } 
                }
            })
        );
        setFormData({ ...formData, hourlyPrice: Number(e.target.value) })
    }

    const handleRegion = (e) => {
        const { region } = formData;
        const taxFactor = 1 + Number(e.value.region) / 100;
        const value = e.value.region === "Ontario" ? "ont" : e.value.region === "British Columbia" ? "bc" : e.value.region === "New York" ? "ny" : "alb"

        const getUpdatedAmount = (prevData, newData) => 
            prevData && newData ? Number(prevData.value) * taxFactor - Number(newData.value) * taxFactor : 0;

        const prevRegionRoadTestData = regionalRoadTestValue.find(item => item.name === region);
        const updatedRegionRoadTestData = regionalRoadTestValue.find(item => item.name === value);
        const prevRegionTheoryData = regionalTheoryLessonValue.find(item => item.name === region);
        const updatedRegionTheoryData = regionalTheoryLessonValue.find(item => item.name === value);
    
        const roadTestDiff = getUpdatedAmount(prevRegionRoadTestData, updatedRegionRoadTestData);
        const theoryDiff = getUpdatedAmount(prevRegionTheoryData, updatedRegionTheoryData);
    
        setInvoices(prevInvoices =>
            prevInvoices.map(inv => {
                if (inv.roadTest) {
                    return { ...inv, amount: (Number(inv.amount) - roadTestDiff).toFixed(2) };
                } else if (inv.theory) {
                    return { ...inv, amount: (Number(inv.amount) - theoryDiff).toFixed(2) };
                }
                return inv;
            })
        );
        handleInvoicesTax(e.value.taxPercentage);
        setFormData(prev => ({ ...prev, region: value, taxPercentage: e.value.taxPercentage, roadTestPrice: e.value.roadTestPrice, theoryLessonPrice: e.value.theoryLessonPrice }));
    };

    const handleUpdateInstallmentPackage = (e) => {
        e.preventDefault();
        dispatch(UpdateInstallmentPackageThunk({
            packageId: selectedPackageData?._id,
            name: formData.packageName,
            hourlyPrice: formData.hourlyPrice,
            taxPercentage: formData.taxPercentage,
            region: formData.region,
            invoices
        }))
    }
    const { errors } = useForm();

    if(success) {
        toast.success("Installment package has been updated successfully.");
        dispatch(ResetUpdateInstallmentPackageData());
        dispatch(GetInstallmentPackagesThunk({ region: ""}));
        setModal();
    }

    if(error) {
        toast.error(errorMessage);
        dispatch(ResetUpdateInstallmentPackageData());
        setModal();
    }

    return (
        <React.Fragment>
            <Modal isOpen={modal.update} toggle={() => setModal({ update: false })} className="modal-dialog-centered" size="lg">
                <ModalBody>
                    <div onClick={(ev) => { ev.preventDefault(); setModal({ update: false }); }} className="close cursor_pointer">
                        <Icon name="cross-sm"></Icon>
                    </div>
                    <div className="p-2">
                        <h5 className="title">Update Installment Package </h5>
                        {getAllRegionLoading ? ( 
                            <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh"}}>
                                <Spinner />
                            </div>
                        ) : (
                            <div className="mt-4">
                                <Form className="row gy-4 mt-4" onSubmit={handleUpdateInstallmentPackage}>
                                    <Col md="12">
                                        <FormGroup>
                                            <label className="form-label">Package Name</label>
                                            <input
                                                className="form-control"
                                                // ref={register({ required: "This field is required" })}
                                                type="text"
                                                name="packageName"
                                                required
                                                defaultValue={formData.packageName}
                                                placeholder='Enter package name'
                                                onChange={(e) => setFormData({ ...formData, packageName: e.target.value })}
                                            />
                                            {errors.packageName && <span className="invalid">{errors.packageName.message}</span>}
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <label className="form-label">Region</label>
                                            <RSelect
                                                name="region"
                                                placeholder="Enter a region"
                                                defaultValue={{value: formData.region, label: formData.region ===  "ont" ? "Ontario" : formData.region === "bc" ? "British Columbia" : formData.region === "ny" ? "New York" : formData.region === "alb" && "Alberta"}}
                                                options={getAllRegionsData?.map((regionData) => {
                                                    return { value: regionData, label: regionData?.region };
                                                })}
                                                onChange={(e)=> handleRegion(e) }
                                            />
                                            {errors.region && <span className="invalid">{errors.region.message}</span>}
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <label className="form-label" htmlFor="hourlyPrice">
                                                Hourly Price
                                            </label>
                                            <input
                                                type="text"
                                                id="hourlyPrice"
                                                className="form-control"
                                                defaultValue={formData.hourlyPrice}
                                                name="hourlyPrice"
                                                onChange={(e) => handleHourlyPrice(e)}
                                                placeholder="Enter hourly price"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="12">
                                        <label className="form-label" htmlFor="invoices">
                                            Invoices
                                        </label>
                                    </Col>
                                    {invoices.map((invoice, index)=> {
                                        return (
                                            <>
                                                <Col md="1" className='d-flex justify-content-center align-items-center mt-4'>
                                                    <span>Inv {index + 1}</span>
                                                </Col>
                                                <Col md="2">
                                                    <FormGroup>
                                                        <label className="form-label" htmlFor="hourlyPrice">
                                                            Lesson hrs
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="hourlyPrice"
                                                            className="form-control"
                                                            name="hourlyPrice"
                                                            value={invoice.lessonHours}
                                                            onChange={(e) => {
                                                                const value = e.target.value;
                                                                setInvoices(prevInvoices =>
                                                                    prevInvoices.map((inv, i) => 
                                                                        i === index ?  { ...inv, lessonHours: Number(value), amount: ( Number(value) * (Number(formData.hourlyPrice) + (Number(formData.hourlyPrice) * (Number(formData.taxPercentage) / 100)))).toFixed(2) } : inv
                                                                    )
                                                                );
                                                            }}
                                                            placeholder="Lesson Hours"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="2">
                                                    <FormGroup>
                                                        <label className="form-label" htmlFor="amount">
                                                            Amount
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="amount"
                                                            className="form-control"
                                                            name="amount"
                                                            readOnly
                                                            value={invoice.amount}
                                                            onChange={(e) => {
                                                                const value = e.target.value;
                                                                setInvoices(prevInvoices =>
                                                                    prevInvoices.map((inv, i) =>
                                                                        i === index ? { ...inv, amount: value } : inv
                                                                    )
                                                                );
                                                            }}
                                                            placeholder="Amount"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="2">
                                                    <label className="form-label" htmlFor="roadTest">
                                                        Road Test
                                                    </label>
                                                    <RSelect
                                                        name="roadTest"
                                                        options={[{ value: true, label: "True" }, {value: false, label: "False" }]}
                                                        defaultValue={{value: invoice.roadTest, label: invoice.roadTest ? "True" : "False" }}
                                                        onChange={(e) => {
                                                            const value = e.value;
                                                            const [regionalRoadTestData] = regionalRoadTestValue.filter((regionalValue) => regionalValue.name === formData.region)
                                                            setInvoices(prevInvoices => 
                                                                prevInvoices.map((inv, i) =>
                                                                    i === index ? inv.roadTest !== value && { ...inv, roadTest: value, amount: value ? (Number(inv.amount) + Number(regionalRoadTestData.value) + (Number(regionalRoadTestData.value) * (Number(formData.taxPercentage) / 100))).toFixed(2)  : (Number(inv.amount) - Number(Number(regionalRoadTestData.value) + (Number(regionalRoadTestData.value) * (Number(formData.taxPercentage) / 100)))).toFixed(2) } : inv
                                                                )
                                                            );
                                                        }}
                                                    />
                                                </Col>
                                                <Col md="2">
                                                    <label className="form-label" htmlFor="theory">
                                                        Theory
                                                    </label>
                                                    <RSelect
                                                        name="theory"
                                                        options={[{value: true, label: "True" }, {value: false, label: "False" }]}
                                                        defaultValue={{value: invoice.theory, label: invoice.theory ? "True" : "False" }}
                                                        onChange={(e) => {
                                                            const value = e.value;
                                                            const [regionaltheoryLessonData] = regionalTheoryLessonValue.filter((regionalValue) => regionalValue.name === formData.region)
                                                            setInvoices(prevInvoices =>
                                                                prevInvoices.map((inv, i) =>
                                                                    i === index ? { ...inv, theory: value, amount: value ? (Number(inv.amount) + Number(regionaltheoryLessonData.value) + (Number(regionaltheoryLessonData.value) * (Number(formData.taxPercentage) / 100))).toFixed(2)  : (Number(inv.amount) - Number(Number(regionaltheoryLessonData.value) + (Number(regionaltheoryLessonData.value) * (Number(formData.taxPercentage) / 100)))).toFixed(2) } : inv
                                                                )
                                                            );
                                                        }}
                                                    />
                                                </Col>
                                                <Col md="2">
                                                    <label className="form-label" htmlFor="dueInDays">
                                                        Due in Days
                                                    </label>
                                                    <input
                                                        type="number"
                                                        id="dueInDays"
                                                        className="form-control"
                                                        name="dueInDays"
                                                        defaultValue={invoice.dueInDays}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            setInvoices(prevInvoices =>
                                                                prevInvoices.map((inv, i) =>
                                                                    i === index ? { ...inv, dueInDays: value } : inv
                                                                )
                                                            );
                                                        }}
                                                        placeholder="Due in days"
                                                    />
                                                </Col>
                                                {(invoices.length > 1 ) && (
                                                    <Col md="1" className="d-flex justify-content-center align-items-center mt-4">
                                                        <div
                                                            color="primary"
                                                            className='cursor_pointer'
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setInvoices(prevInvoices =>
                                                                    prevInvoices.filter((inv, i) =>
                                                                        index + 1 !== inv.invoiceNumber
                                                                    )
                                                                );
                                                                setInvoices(prevInvoices =>
                                                                    prevInvoices.map((inv, i) => { return  {...inv, invoiceNumber: i + 1 } }
                                                                    )
                                                                );
                                                                        // setFormData({...formData, licenseTypes: [...formData.licenseTypes]});
                                                            }}
                                                        >
                                                            <Icon className="d-flex align-items-center" style={{ fontSize: '24px' }} name="cross" size="3x"></Icon>
                                                        </div>
                                                    </Col>
                                                )}
                                            </>
                                        )
                                    })}
                                    <Col md="1" className="d-flex justify-content-center align-items-center mt-4">
                                        <div
                                            color="primary"
                                            className='cursor_pointer'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setInvoices([...invoices, {
                                                    invoiceNumber: invoices.length + 1,
                                                    lessonHours: 0,
                                                    amount: 0,
                                                    roadTest: false,
                                                    theory: false,
                                                    dueInDays: 30
                                                }])
                                                // setFormData({...formData, licenseTypes: [...formData.licenseTypes]});
                                            }}
                                        >
                                            <Icon className="d-flex align-items-center" style={{ fontSize: '24px' }} name="plus" size="3x"></Icon>
                                        </div>
                                    </Col>


                                    {/* {formData.invoices.length !== 0 && (
                                        <Col md="6">
                                            {formData.invoices.map((invoice, index) => {
                                            return (
                                                <Col md="12" className='pl-0'>
                                                <FormGroup
                                                    style={{
                                                    backgroundColor: "#dbdfea",
                                                    borderRadius: "5px",
                                                    border: "10px solid #dbdfea",
                                                    }}
                                                    className="form-label mt-2 ml-0 d-flex justify-content-between"
                                                >
                                                    {invoice}
                                                    <Button
                                                        color="danger"
                                                        size="sm"
                                                        className="ml-2"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            const fileteredLicenseType = formData.invoices.filter((_, idx) => index !== idx);
                                                            setFormData({...formData, invoices: fileteredLicenseType });
                                                        }}
                                                    >
                                                    <Icon name="cross"></Icon>
                                                    </Button>
                                                </FormGroup>
                                                </Col>
                                            );
                                            })}
                                        </Col>
                                    )} */}
                                    
                                    <Col md="12"/>
                                    <Col size="12">
                                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                            <li>
                                                <Button disabled={loading} color="primary" size="md" type="submit" onClick={(e) => handleUpdateInstallmentPackage(e)}>
                                                    {loading ? <Spinner /> : "Update Installment Package"}
                                                </Button>
                                            </li>
                                            <li>
                                                <div onClick={(ev) => { ev.preventDefault(); setModal(); }} className="link link-light cursor_pointer">
                                                    Cancel
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Form>
                            </div>
                        )
                        
                        }
                    </div>
                </ModalBody>
            </Modal>

        </React.Fragment>
    )
}

export default UpdateInstallmentPackageModal
