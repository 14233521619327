import { configureStore } from "@reduxjs/toolkit";
import { ConfigurationReducers } from "./ConfigurationSlice";
import { dashboardSlice } from "./DashboardSlice";
import { droppedStudentsSlice } from "./DroppedStudentsSlice";
import { GeoFencingReducers } from "./GeoFencingSlice";
import { ImageUploaderReducers } from "./ImageUploaderSlice";
import { instructorsReducers } from "./InstructorSlice";
import { LessonsReducers } from "./LessonsSlice";
import { lmsStudentReducers } from "./LmsStudentSlice";
import { authSlice } from "./Login";
import { pickupPointsReducers } from "./PickupPoints";
import { PromotionsReducers } from "./Promotions";
import { StudentsReducers } from "./StudentsSlice";
import { unservedStudentsReducers } from "./UnservedStudentsSlice";
import { invoicesReducers } from "./Invoices";
import { surveyFormReducers } from "./SurveyFormSlice.js/index.js";
import { InCarLessonSheetReducers } from "./InCarSheetSlice/index.js";
import { InstructorCancelledLessonReducers } from "./InstructorCancelledLessonsSlice/index.js";
import { InstructorDevicesReducers } from "./InstructorDevicesSlice/index.js";
import { PackageReducers } from "./PackagesSlice/index.js";

export const store = configureStore({
  reducer: {
    login: authSlice,
    Students: StudentsReducers,
    allStats: dashboardSlice,
    instructors: instructorsReducers,
    lessons: LessonsReducers,
    pickupPoints: pickupPointsReducers,
    unservedStudents: unservedStudentsReducers,
    imageUploader: ImageUploaderReducers,
    promotions: PromotionsReducers,
    droppedStudent: droppedStudentsSlice,
    lmsStudents: lmsStudentReducers,
    configuration: ConfigurationReducers,
    geoFencing: GeoFencingReducers,
    invoices: invoicesReducers,
    surveyForm: surveyFormReducers,
    inCarLessonSheet: InCarLessonSheetReducers,
    instructorCancelledLesson: InstructorCancelledLessonReducers,
    instructorDevices: InstructorDevicesReducers,
    packages: PackageReducers
  },
});
