import moment from 'moment';
import React from 'react'
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';

const ShowInCarSheetDetailModal = ({ modal, setModal, selectedInCarSheetData }) => {
    return (
        <React.Fragment>
            <Modal
                isOpen={modal.view}
                toggle={() => setModal({ view: false })}
                className="modal-md"
            >
                <ModalHeader toggle={() => setModal({ view: false })}> InCar Sheet Detail </ModalHeader>
                <ModalBody>
                    <Row className="gy-3 py-1">
                        {selectedInCarSheetData?.studentName &&
                            <Col sm="6" id="preview-event-description-check">
                                <h6 className="overline-title">Student Name</h6>
                                <p id="preview-event-description">
                                    {selectedInCarSheetData?.studentName}
                                </p>
                            </Col>
                        }
                        {selectedInCarSheetData?.instructorName &&
                            <Col sm="6" id="preview-event-description-check">
                                <h6 className="overline-title">Instructor Name</h6>
                                <p id="preview-event-description">
                                    {selectedInCarSheetData?.instructorName}
                                </p>
                            </Col>
                        }
                        {selectedInCarSheetData?.day &&
                            <Col sm="6" id="preview-event-description-check">
                                <h6 className="overline-title">Day</h6>
                                <p id="preview-event-description">
                                    {selectedInCarSheetData?.day}
                                </p>
                            </Col>
                        }
                        {selectedInCarSheetData?.duration &&
                            <Col sm="6" id="preview-event-description-check">
                                <h6 className="overline-title">Duration</h6>
                                <p id="preview-event-description">
                                    {selectedInCarSheetData?.duration}
                                </p>
                            </Col>
                        }


                        {selectedInCarSheetData?.dateTime &&
                            <Col sm="6">
                                <h6 className="overline-title">Date Time</h6>
                                <p id="preview-event-start">
                                    {moment.utc(selectedInCarSheetData?.dateTime).format("DD-MM-YYYY")}
                                </p>
                            </Col>
                        }

                        <Col sm="6" id="preview-event-description-check">
                            <h6 className="overline-title">Home Pickup</h6>
                            <p id="preview-event-description">
                                {selectedInCarSheetData?.homePickup ? "True" : "False"}
                            </p>
                        </Col>

                        <Col sm="6" id="preview-event-description-check">
                            <h6 className="overline-title">In-Car Sheet Filled</h6>
                            <p id="preview-event-description">
                                {selectedInCarSheetData?.inCarSheetFilled ? "True" : "False"}
                            </p>
                        </Col>
                        <Col sm="6" id="preview-event-description-check">
                            <h6 className="overline-title">Is Complementary</h6>
                            <p id="preview-event-description">
                                {selectedInCarSheetData?.isComplementary ? "True": "False"}
                            </p>
                        </Col>
                        <Col sm="6" id="preview-event-description-check">
                            <h6 className="overline-title">Is RoadTest</h6>
                            <p id="preview-event-description">
                                {selectedInCarSheetData?.isRoadTest ? "True" : "False"}
                            </p>
                        </Col>
                        <Col sm="6" id="preview-event-description-check">
                            <h6 className="overline-title">Lesson sheet Filled</h6>
                            <p id="preview-event-description">
                                {selectedInCarSheetData?.lessonSheetFilled ? "True" : "False" }
                            </p>
                        </Col>

                        {selectedInCarSheetData?.purchaseDate &&
                            <Col sm="6" id="preview-event-end-check">
                                <h6 className="overline-title">Purchase Date</h6>
                                <p id="preview-event-end">
                                    {moment.utc(selectedInCarSheetData?.purchaseDate).format("DD-MM-YYYY")}
                                </p>
                            </Col>
                        }
                        {selectedInCarSheetData?.expiryDate &&
                            <Col sm="6" id="preview-event-end-check">
                                <h6 className="overline-title">Expiry Date</h6>
                                <p id="preview-event-end">
                                    {moment.utc(selectedInCarSheetData?.expiryDate).format("DD-MM-YYYY")}
                                </p>
                            </Col>
                        }
                        {selectedInCarSheetData?.status &&
                            <Col sm="6" id="preview-event-description-check">
                                <h6 className="overline-title">Status</h6>
                                <p id="preview-event-description">
                                    {selectedInCarSheetData?.status}
                                </p>
                            </Col>
                        }

                        {selectedInCarSheetData?.roadTestType &&
                            <Col sm="6" id="preview-event-description-check">
                                <h6 className="overline-title">Road Test Type</h6>
                                <p id="preview-event-description">
                                    {selectedInCarSheetData?.roadTestType}
                                </p>
                            </Col>
                        }
                        {selectedInCarSheetData?.roadTestTime &&
                            <Col sm="6" id="preview-event-description-check">
                                <h6 className="overline-title">Road Test Time</h6>
                                <p id="preview-event-description">
                                    {moment.utc(selectedInCarSheetData?.roadTestTime).format("hh:mm A")}
                                </p>
                            </Col>
                        }
                        {selectedInCarSheetData?.roadTestAddress &&
                            <Col sm="12" id="preview-event-description-check">
                                <h6 className="overline-title">Road Test Address</h6>
                                <p id="preview-event-description">{selectedInCarSheetData?.roadTestAddress}</p>
                            </Col>
                        }
                        {selectedInCarSheetData?.pickupLocation &&
                            <Col sm="12" id="preview-event-description-check">
                                <h6 className="overline-title">Pickup Location</h6>
                                <p id="preview-event-description">{selectedInCarSheetData?.pickupLocation}</p>
                            </Col>
                        }
                    </Row>
                </ModalBody>
            </Modal>

        </React.Fragment>
    )
}

export default ShowInCarSheetDetailModal;
