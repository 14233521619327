import React, { useEffect, useState } from 'react'
import { Button, Col, Form, FormGroup, Modal, ModalBody, Spinner } from 'reactstrap';
import { Icon } from '../../components/Component';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ResetUpdateRegionData, UpdateRegionThunk } from '../../redux/ConfigurationSlice/UpdateRegionSlice';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { GetAllRegionsThunk } from '../../redux/ConfigurationSlice/GetAllRegionsSlice';

const UpdateRegionModal = ({ modal, setModal, selectedRegionData }) => {
    const dispatch = useDispatch();

    const { loading, success, error, errorMessage } = useSelector((state)=> state.configuration.updateRegion);

    const [licenseType, setLicenseType] = useState("");
    const [formData, setFormData] = useState({
        region: "",
        taxPercentage: 0,
        roadTestPrice: 0,
        theoryLessonPrice: 0,
        licenseTypes: []
    }) 

    useEffect(()=> {
        if(selectedRegionData) {
            setFormData(prevFormData => ({
                ...prevFormData,
                region: selectedRegionData?.region,
                taxPercentage: selectedRegionData?.taxPercentage,
                roadTestPrice: selectedRegionData?.roadTestPrice,
                theoryLessonPrice: selectedRegionData?.theoryLessonPrice,
                licenseTypes: [...prevFormData.licenseTypes, ...selectedRegionData.licenseTypes.map(lt => lt.value)]
            }));
        }
    }, [selectedRegionData]);

    const handleUpdateRegion = (e) => {
        e.preventDefault();
        dispatch(UpdateRegionThunk({ 
            regionId: selectedRegionData._id,
            region: formData.region, 
            licenseTypes: formData.licenseTypes,
            taxPercentage: formData.taxPercentage,
            roadTestPrice: formData.roadTestPrice,
            theoryLessonPrice: formData.theoryLessonPrice
        }))
    }

    const { errors } = useForm();

    if(success) {
        toast.success("Region has been added successfully.");
        dispatch(GetAllRegionsThunk());
        dispatch(ResetUpdateRegionData());
        setModal();
    }

    if(error) {
        toast.error(errorMessage);
        dispatch(ResetUpdateRegionData());
        setModal();
    }

    return (
        <React.Fragment>
            <Modal isOpen={modal.updateRegion} toggle={() => setModal({ updateRegion: false })} className="modal-dialog-centered" size="lg">
                <ModalBody>
                    <div onClick={(ev) => { ev.preventDefault(); setModal({ updateRegion: false }); }} className="close cursor_pointer">
                        <Icon name="cross-sm"></Icon>
                    </div>
                    <div className="p-2">
                        <h5 className="title">Update Region </h5>
                        <div className="mt-4">
                            <Form className="row gy-4 mt-4" onSubmit={handleUpdateRegion}>
                            <Col md="12">
                                    <FormGroup>
                                        <label className="form-label">Name</label>
                                        <input
                                            className="form-control"
                                            // ref={register({ required: "This field is required" })}
                                            type="text"
                                            name="region"
                                            required
                                            placeholder='Enter a region name'
                                            defaultValue={selectedRegionData.region}
                                            onChange={(e) => setFormData({ ...formData, region: e.target.value })}
                                        />
                                        {errors.region && <span className="invalid">{errors.region.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label className="form-label">Tax Percentage</label>
                                        <input
                                            className="form-control"
                                            // ref={register({ required: "This field is required" })}
                                            type="text"
                                            name="taxPercentage"
                                            required
                                            placeholder='Enter a tax percentage'
                                            defaultValue={selectedRegionData.taxPercentage}
                                            onChange={(e) => setFormData({ ...formData, taxPercentage: e.target.value })}
                                        />
                                        {errors.taxPercentage && <span className="invalid">{errors.taxPercentage.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label className="form-label">Road Test Price</label>
                                        <input
                                            className="form-control"
                                            // ref={register({ required: "This field is required" })}
                                            type="text"
                                            name="roadTestPrice"
                                            required
                                            placeholder='Enter a road test price'
                                            defaultValue={selectedRegionData.roadTestPrice}
                                            onChange={(e) => setFormData({ ...formData, roadTestPrice: e.target.value })}
                                        />
                                        {errors.roadTestPrice && <span className="invalid">{errors.roadTestPrice.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label className="form-label">Theory Lesson Price</label>
                                        <input
                                            className="form-control"
                                            // ref={register({ required: "This field is required" })}
                                            type="text"
                                            name="theoryLessonPrice"
                                            required
                                            placeholder='Enter a theory lesson price'
                                            defaultValue={selectedRegionData.theoryLessonPrice}
                                            onChange={(e) => setFormData({ ...formData, theoryLessonPrice: e.target.value })}
                                        />
                                        {errors.theoryLessonPrice && <span className="invalid">{errors.theoryLessonPrice.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="11">
                                    <FormGroup>
                                        <label className="form-label" htmlFor="address">
                                            License Type
                                        </label>
                                        <input
                                            type="text"
                                            id="address"
                                            className="form-control"
                                            name="address"
                                            onChange={(e) => setLicenseType(e.target.value)}
                                            placeholder="Enter license type"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="1" className="d-flex justify-content-center align-items-center mt-4">
                                    <div
                                        color="primary"
                                        className='cursor_pointer'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setFormData({...formData, licenseTypes: [...formData.licenseTypes, licenseType]});
                                        }}
                                    >
                                        <Icon className="d-flex align-items-center" style={{ fontSize: '24px' }} name="plus" size="3x"></Icon>
                                    </div>
                                </Col>
                                {formData.licenseTypes.length !== 0 && (
                                    <Col md="6">
                                        {formData.licenseTypes.map((licenseType, index) => {
                                        return (
                                            <Col md="12" className='pl-0'>
                                                <FormGroup
                                                    style={{
                                                        backgroundColor: "#dbdfea",
                                                        borderRadius: "5px",
                                                        border: "10px solid #dbdfea",
                                                    }}
                                                    className="form-label mt-2 ml-0 d-flex justify-content-between"
                                                >
                                                    {licenseType}
                                                    <Button
                                                        color="danger"
                                                        size="sm"
                                                        className="ml-2"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            const fileteredLicenseType = formData.licenseTypes.filter((_, idx) => index !== idx);
                                                            setFormData({...formData, licenseTypes: fileteredLicenseType });
                                                        }}
                                                    >
                                                    <Icon name="cross"></Icon>
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        );
                                        })}
                                    </Col>
                                )}
                                
                                <Col md="6"/>
                                <Col size="12">
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button disabled={loading} color="primary" size="md" type="submit" onClick={(e) => handleUpdateRegion(e)}>
                                                {loading ? <Spinner /> : "Update Region"}
                                            </Button>
                                        </li>
                                        <li>
                                            <div onClick={(ev) => { ev.preventDefault(); setModal(); }} className="link link-light cursor_pointer">
                                                Cancel
                                            </div>
                                        </li>
                                    </ul>
                                </Col>
                            </Form>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default UpdateRegionModal;
