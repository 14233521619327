import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

// Delete Regular Package
const DeleteRegularPackageThunk = createAsyncThunk(
    "DeleteRegularPackage/DeleteRegularPackageThunk",
    async ({ studentId }, { rejectWithValue }) => {
        try {
            const response = await axios.delete(`${ApiEndpoints.Packages.DeleteRegularPackage}?studentId=${studentId}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
            });
            return response.data;
        } catch (error) {
            console.log("error", error);
            return rejectWithValue(error.response.data);
        }
    }
);

const DeleteRegularPackageSlice = createSlice({
    name: "DeleteRegularPackages",
    initialState: {
        deletedRegularPackageData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetDeleteRegularPackageData: (state, action) => {
            state.deletedRegularPackageData = [];
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [DeleteRegularPackageThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [DeleteRegularPackageThunk.fulfilled]: (state, action) => {
            state.deletedRegularPackageData = action.payload.data;
            state.success = true;
            state.loading = false;
        },
        [DeleteRegularPackageThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload.message;
            state.error = true;
            state.loading = false;
        },
    },
});

export default DeleteRegularPackageSlice.reducer;

export const { ResetDeleteRegularPackageData } = DeleteRegularPackageSlice.actions;

export { DeleteRegularPackageThunk };
