import moment from 'moment'
import React from 'react'
import { Button, Card, CardBody, CardText, CardTitle, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'

const ViewInstallmentPackageDetailModal = ({modal, setModal, selectedPackageData}) => {
    return (
        <React.Fragment>
            <Modal
                isOpen={modal.view}
                toggle={() => setModal({ view: false })}
                className="modal-md"
            >
                <ModalHeader
                    className="d-flex justify-content-between"
                    tag="h3"
                    toggle={() => setModal({ view: false })}
                >
                    Package Detail
                </ModalHeader>
                <ModalBody>
                        <React.Fragment>
                            <div className="d-flex justify-content-between">
                            <div>
                                <div>
                                <span> <span style={{fontWeight: "600", color: "#000000"}}> Package Name: </span> {selectedPackageData?.name }</span>
                                </div>
                                <div>
                                <span> <span style={{fontWeight: "600", color: "#000000"}}> Hourly Price: </span> ${selectedPackageData?.hourlyPrice}</span>
                                </div>
                                <div>
                                <span> <span style={{fontWeight: "600", color: "#000000"}}> Total: </span>${selectedPackageData?.taxPercentage.toFixed(2)}  </span>
                                </div>
                                {/* <h6> Total : ${selectedPackageData?.invoiceTotal}</h6> */}
                                <span> <span style={{fontWeight: "600", color: "#000000"}}> Region: </span> {selectedPackageData.region}</span>
                                <br/>
                                <br/>
                            </div>
                            <div>
                                {/* <Button disabled={EmailInvoiceLoading} onClick={()=> dispatch(EmailInvoiceThunk({invoiceId: selectedPackageData?._id}))} color="primary" size="md">Email Pdf </Button> */}
                            </div>
                            </div>
                            {selectedPackageData?.invoices?.map((invoiceData, index)=>{                  
                                return ( 
                                    <Card className="card-bordered w-100 mb-1">
                                        <CardBody className="card-inner">
                                            <CardText tag="p" style={{ color: "rgb(92 106 124)", marginBottom: "0.2rem" }}>
                                                <div className="d-flex justify-content-between">
                                                    <h6>{invoiceData?.invoiceNumber}</h6>
                                                </div>
                                                <Row className="d-flex justify-content-between mt-3" style={{flex: "left"}}>
                                                    <Col md="4">
                                                        <span>Invoice Number: </span>
                                                        <br/>
                                                        <span style={{color: 'black'}}>{invoiceData?.invoiceNumber}</span>
                                                    </Col>
                                                    <Col md="4">
                                                        <span >Lesson Hours</span>
                                                        <br/>
                                                        <span style={{color: 'black', flex: "left"}}>{invoiceData?.lessonHours}</span>
                                                    </Col>
                                                    <Col md="4">
                                                        <span >Amount</span>
                                                        <br/>
                                                        <span style={{color: 'black'}}>{invoiceData?.amount}</span>
                                                    </Col>
                                                </Row>
                                                <Row className="d-flex justify-content-between mt-3">
                                                    <Col md="4">
                                                        <span >Road Test</span>
                                                        <br/>
                                                        <span style={{color: 'black'}}>{invoiceData?.roadTest ? "True": "False"}</span>
                                                    </Col>
                                                    <Col md="4">
                                                        <span >Theory</span>
                                                        <br/>
                                                        <span style={{color: 'black'}}>{invoiceData?.theory ? "True": "False"}</span>
                                                    </Col>
                                                    <Col md="4">
                                                        <span >Due in Days</span>
                                                        <br/>
                                                        <span style={{color: 'black'}}>{invoiceData?.dueInDays}</span>
                                                    </Col>
                                                </Row>
                                            </CardText>
                                            <CardTitle tag="h6"></CardTitle>
                                        </CardBody>
                                    </Card>
                                )
                            })}
                        </React.Fragment>
                        
                </ModalBody>
            </Modal>

        </React.Fragment>
    )
}

export default ViewInstallmentPackageDetailModal;
