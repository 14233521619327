import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

// add Installment Package
const AddInstallmentPackageThunk = createAsyncThunk(
    "AddInstallmentPackage/AddInstallmentPackageThunk",
    async ({name, hourlyPrice, taxPercentage, region, invoices}, { rejectWithValue }) => {
        try {
            const response = await axios.post(ApiEndpoints.Packages.AddInstallmentPackage,
                {name, hourlyPrice, taxPercentage, region, invoices},
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
                });
            return response.data;
        } catch (error) {
            console.log("error", error);
            const errorMessage = error?.response?.data?.message || "Something went wrong";
            return rejectWithValue(errorMessage);
        }
    }
);

const AddInstallmentPackageSlice = createSlice({
    name: "AddInstallmentPackage",
    initialState: {
        AddInstallmentPackageData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetAddInstallmentPackageData: (state, action) => {
            state.AddInstallmentPackageData = [];
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [AddInstallmentPackageThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [AddInstallmentPackageThunk.fulfilled]: (state, action) => {
            state.AddInstallmentPackageData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [AddInstallmentPackageThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.AddInstallmentPackageData = [];
        },
    },
});

export default AddInstallmentPackageSlice.reducer;

export const { ResetAddInstallmentPackageData } = AddInstallmentPackageSlice.actions;

export { AddInstallmentPackageThunk };
