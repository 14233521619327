import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const  UpdateRegionThunk = createAsyncThunk(
    "UpdateRegion/UpdateRegionThunk",
    async ({ regionId, region, licenseTypes, taxPercentage, roadTestPrice, theoryLessonPrice }, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${ApiEndpoints.Configuration.UpdateRegion}?regionId=${regionId}`,
                { region, licenseTypes, taxPercentage, roadTestPrice, theoryLessonPrice },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
                }
            );
            return response.data;
        } catch (error) {
            console.log("error", error);
            return rejectWithValue(error.response.data);
        }
    }
);

const UpdateRegionSlice = createSlice({
    name: "UpdateRegion",
    initialState: {
        UpdateRegionData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetUpdateRegionData: (state, action) => {
            state.UpdateRegionData = [];
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },

    extraReducers: {
        [UpdateRegionThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [UpdateRegionThunk.fulfilled]: (state, action) => {
            state.UpdateRegionData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [UpdateRegionThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload.message;
            state.error = true;
            state.loading = false;
        },
    },
});

export default UpdateRegionSlice.reducer;

export const { ResetUpdateRegionData } = UpdateRegionSlice.actions;

export { UpdateRegionThunk };
