import React from 'react';
import { Button, Col, Form, Modal, ModalBody, Spinner } from 'reactstrap';
import { Icon } from '../../../components/Component';
import { useDispatch, useSelector } from 'react-redux';
import { ResetSendStudentInvoiceData, SendStudentInvoiceThunk } from '../../../redux/StudentsSlice/ManualInvoicesSlice/SendStudentInvoiceSlice';
import toast from 'react-hot-toast';
import { GetStudentPersonalDetailThunk } from '../../../redux/StudentsSlice/StudentPersonalDetail';

const SendStudentInvoiceModal = ({ modal, setModal, countryCode, invoiceNumber, studentId }) => {
    const dispatch = useDispatch();

    const { loading, success, error, errorMessage} = useSelector((state)=> state.Students.studentInvoices.sendStudentInvoice);
    const handleSendInvoice = (e) => {
        e.preventDefault();
        dispatch(SendStudentInvoiceThunk({invoiceNumber, studentId }));
    }
    
    if(success) {
        toast.success("Invoice has been send successfully.");
        dispatch(ResetSendStudentInvoiceData());
        dispatch(GetStudentPersonalDetailThunk({studentId}));
        setModal();
    }
    if(error) {
        toast.error(errorMessage);
        dispatch(ResetSendStudentInvoiceData());
        setModal();
    }

    return (
        <React.Fragment>
            <Modal
                isOpen={modal.sendInvoice}
                toggle={() => setModal({ sendInvoice: false })}
                className="modal-dialog-centered"
                size="md"
            >
                <ModalBody>
                    <div
                        className="close cursor_pointer"
                        onClick={(ev) => {
                            ev.preventDefault();
                            setModal({ sendInvoice: false });
                        }}
                    >
                        <Icon name="cross-sm"></Icon>
                    </div>
                    <div className="p-2">
                        <h4 className="title">Send Invoice</h4>
                        <div className="mt-4">
                            <Form className="row gy-4 mt-4 mb-5">
                                <Col md="12">
                                    <div>
                                        <span>Are you sure you want to send Invoice?</span>
                                    </div>
                                </Col>
                                <Col size="12">
                                    <ul className="d-flex justify-content-between flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button disabled={loading} onClick={handleSendInvoice} color="gray" size="md" type="submit">
                                                {loading ? <Spinner /> : "Send Invoice"}
                                            </Button>
                                        </li>
                                        <li>
                                            <div
                                                onClick={(ev) => {
                                                ev.preventDefault();
                                                setModal({ sendInvoice: false });
                                                }}
                                                className="link link-light cursor_pointer"
                                            >
                                                Cancel
                                            </div>
                                        </li>
                                    </ul>
                                </Col>
                            </Form>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

        </React.Fragment>
    )
}

export default SendStudentInvoiceModal;
