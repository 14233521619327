import React from 'react';
import { Button, Col, Form, Modal, ModalBody, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import { Icon } from '../../../components/Component';
import { ExpireStudentInvoiceThunk, ResetExpireStudentInvoiceData } from '../../../redux/StudentsSlice/ManualInvoicesSlice/ExpireStudentInvoiceSlice';
import { GetStudentPersonalDetailThunk } from '../../../redux/StudentsSlice/StudentPersonalDetail';

const ExpireStudentInvoiceModal = ({ modal, setModal, invoiceId, studentId, invoiceNumber }) => {
    const dispatch = useDispatch();

    const { loading, success, error, errorMessage} = useSelector((state)=> state.Students.studentInvoices.expireStudentInvoice);
    const handleExpireInvoice = (params) => {
        dispatch(ExpireStudentInvoiceThunk({ invoiceId, studentId, invoiceNumber }))
    }

    if(success) {
        toast.success("Invoice has been expired successfully.");
        dispatch(ResetExpireStudentInvoiceData());
        dispatch(GetStudentPersonalDetailThunk({ studentId }));
        setModal();
    }
    if(error) {
        toast.error(errorMessage);
        dispatch(ResetExpireStudentInvoiceData());
        setModal();
    }

    return (
        <React.Fragment>
            <Modal
                isOpen={modal.expire}
                toggle={() => setModal({ expire: false })}
                className="modal-dialog-centered"
                size="md"
            >
                <ModalBody>
                    <div
                        className="close cursor_pointer"
                        onClick={(ev) => {
                            ev.preventDefault();
                            setModal({ expire: false });
                        }}
                    >
                        <Icon name="cross-sm"></Icon>
                    </div>
                    <div className="p-2">
                        <h4 className="title">Expire Invoice</h4>
                        <div className="mt-4">
                            <Form className="row gy-4 mt-4 mb-5">
                                <Col md="12">
                                    <div>
                                        <span>Are you sure you want to expire Invoice?</span>
                                    </div>
                                </Col>
                                <Col size="12">
                                    <ul className="d-flex justify-content-between flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button disabled={loading} onClick={handleExpireInvoice} color="gray" size="md" type="submit">
                                                {loading ? <Spinner /> : "Expire Invoice"}
                                            </Button>
                                        </li>
                                        <li>
                                            <div
                                                onClick={(ev) => {
                                                ev.preventDefault();
                                                setModal({ expire: false });
                                                }}
                                                className="link link-light cursor_pointer"
                                            >
                                                Cancel
                                            </div>
                                        </li>
                                    </ul>
                                </Col>
                            </Form>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

        </React.Fragment>
    )
}

export default ExpireStudentInvoiceModal;
