import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

// update Installment Package
const UpdateInstallmentPackageThunk = createAsyncThunk(
    "UpdateInstallmentPackage/UpdateInstallmentPackageThunk",
    async ({name, hourlyPrice, taxPercentage, region, invoices, packageId}, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${ApiEndpoints.Packages.UpdateInstallmentPackage}?packageId=${packageId}`,
                {name, hourlyPrice, taxPercentage, region, invoices},
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
                });
            return response.data;
        } catch (error) {
            console.log("error", error);
            const errorMessage = error?.response?.data?.message || "Something went wrong";
            return rejectWithValue(errorMessage);
        }
    }
);

const UpdateInstallmentPackagelice = createSlice({
    name: "UpdateInstallmentPackage",
    initialState: {
        UpdateInstallmentPackageData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetUpdateInstallmentPackageData: (state, action) => {
            state.UpdateInstallmentPackageData = [];
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [UpdateInstallmentPackageThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [UpdateInstallmentPackageThunk.fulfilled]: (state, action) => {
            state.UpdateInstallmentPackageData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [UpdateInstallmentPackageThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.UpdateInstallmentPackageData = [];
        },
    },
});

export default UpdateInstallmentPackagelice.reducer;

export const { ResetUpdateInstallmentPackageData } = UpdateInstallmentPackagelice.actions;

export { UpdateInstallmentPackageThunk };
