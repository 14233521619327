import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../../utils/ApiEndpoints";

// expire student Invoice
const ExpireStudentInvoiceThunk = createAsyncThunk(
    "ExpireStudentInvoice/ExpireStudentInvoiceThunk",
    async ({ invoiceId, studentId, invoiceNumber }, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${ApiEndpoints.StudentsEndPoint.ManualInvoice.ExpireStudentInvoice}?studentId=${studentId}&invoiceId=${invoiceId}`,
                { invoiceNumber },
                {
                headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
                }
            );
            return response.data;
        } catch (error) {
        console.log("error", error);
            return rejectWithValue(error.response.data);
        }
    }
);

const ExpireStudentInvoiceSlice = createSlice({
    name: "ExpireStudentInvoice",
    initialState: {
        ExpireStudentInvoiceData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetExpireStudentInvoiceData: (state, action) => {
        state.ExpireStudentInvoiceData = [];
        state.success = false;
        state.error = false;
        state.loading = false;
        state.errorMessage = "";
        },
    },
    extraReducers: {
        [ExpireStudentInvoiceThunk.pending]: (state, action) => {
        state.loading = true;
        state.success = false;
        state.error = false;
        },
        [ExpireStudentInvoiceThunk.fulfilled]: (state, action) => {
        state.ExpireStudentInvoiceData = [...state.ExpireStudentInvoiceData, action.payload.data];
        state.success = true;
        state.loading = false;
        },
        [ExpireStudentInvoiceThunk.rejected]: (state, action) => {
        state.errorMessage = action.payload.message;
        state.error = true;
        state.loading = false;
        },
    },
});

export default ExpireStudentInvoiceSlice.reducer;

export const { ResetExpireStudentInvoiceData } = ExpireStudentInvoiceSlice.actions;

export { ExpireStudentInvoiceThunk };
