import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../../utils/ApiEndpoints";

// send student Invoice
const SendStudentInvoiceThunk = createAsyncThunk(
    "SendStudentInvoice/SendStudentInvoiceThunk",
    async ({ invoiceNumber, studentId }, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${ApiEndpoints.StudentsEndPoint.ManualInvoice.SendStudentInvoice}?studentId=${studentId}`,
                { invoiceNumber },
                {
                headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
                }
            );
            return response.data;
        } catch (error) {
        console.log("error", error);
            return rejectWithValue(error.response.data);
        }
    }
);

const SendStudentInvoiceSlice = createSlice({
    name: "SendStudentInvoice",
    initialState: {
        SendStudentInvoiceData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetSendStudentInvoiceData: (state, action) => {
        state.SendStudentInvoiceData = [];
        state.success = false;
        state.error = false;
        state.loading = false;
        state.errorMessage = "";
        },
    },
    extraReducers: {
        [SendStudentInvoiceThunk.pending]: (state, action) => {
        state.loading = true;
        state.success = false;
        state.error = false;
        },
        [SendStudentInvoiceThunk.fulfilled]: (state, action) => {
        state.SendStudentInvoiceData = [...state.SendStudentInvoiceData, action.payload.data];
        state.success = true;
        state.loading = false;
        },
        [SendStudentInvoiceThunk.rejected]: (state, action) => {
        state.errorMessage = action.payload.message;
        state.error = true;
        state.loading = false;
        },
    },
});

export default SendStudentInvoiceSlice.reducer;

export const { ResetSendStudentInvoiceData } = SendStudentInvoiceSlice.actions;

export { SendStudentInvoiceThunk };
