import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormGroup, Modal, ModalBody, Spinner } from 'reactstrap';
import { Icon, RSelect } from '../../components/Component';
import { useDispatch, useSelector } from 'react-redux';
import { NumberOfLessonsOptions } from '../pre-built/orders/OrderData';
import { GetPackagesThunk } from '../../redux/StudentsSlice/GetPackagesSlice';
import { GetInstallmentPackagesThunk } from '../../redux/PackagesSlice/GetInstallmentPackagesSlice';
import {  GetAllStudentsThunk } from '../../redux/StudentsSlice/GetAllStudentSlice';
import { AddStudentThunk } from "../../redux/StudentsSlice/AddStudentSlice";
import toast from 'react-hot-toast';
import { ResetAddStudentData } from '../../redux/StudentsSlice/AddStudentSlice';
import { useForm } from 'react-hook-form';

const invoiceReasoning = [
    { label: "Checkout bug", value: "Checkout bug" },
    { label: "Manual installment payments", value: "Manual installment payments"  },
    { label: "Out of town road test", value: "Out of town road test"  },
    { label: "Custom package", value: "Custom package"  },
    { label: "Other", value: "Other"  },
]

const AddStudentModal = ({ modal, setModal }) => {
    const dispatch = useDispatch();

    const { packagesData } = useSelector((state) => state.Students.getPackages)
    const { 
        loading: installmentPackagesLoading,
        installmentPackagesData
    } = useSelector((state) => state.packages.getInstallmentPackages)

    const {
        loading: AddStudentLoading,
        success: AddStudentSuccess,
        error: AddStudentError,
        errorMessage: AddStudentErrorMessage,
    } = useSelector((state) => state.Students.addStudent);

    const { 
        loading: getInstructorListLoading, 
        instructorsList 
    } = useSelector((state) => state.instructors.getInstructorList);

    const [formData, setFormData] = useState({
        countryCode: "CA",
        numberOfLessons: 10,
        reason: "",
        firstName: "", 
        lastName: "", 
        email: "", 
        password: "", 
        phoneNumber: "", 
        postalCode: "", 
        paymentId: "",
        homePickup: true
    });
    const [isOther, setIsOther] = useState(false);
    const [isRegularPackage, setIsRegularPackage] = useState(false);
    const [pages, setPages] = useState({
        page1: true,
        page2: false,
        page3: false
    });

    useEffect(()=>{
        dispatch(GetPackagesThunk());
        dispatch(GetInstallmentPackagesThunk({ region: "" }));
    }, [dispatch]);

    useEffect(() => {
        if(packagesData) {
            const [filteredPackageData] = packagesData?.packages?.filter((pkgData, index) => pkgData?.uuid === "8d3a19a1-76e3-4cbb-ae45-9d0c0b5f8e65")
            setFormData({ ...formData, packageData:filteredPackageData});
        }
        if(instructorsList) {
            const [filteredPackageData] = instructorsList?.instructors?.filter((instructorData, index) => instructorData?._id === "625d10a6494c6f87681dc0ee")
            setFormData({ ...formData, instructorData: filteredPackageData});

        }
    }, [packagesData, instructorsList])

    const handleAddStudentReason = (e, value) => {
        e.preventDefault(); 
        setIsRegularPackage(value); 
        setPages({ page1: false, page2: true }); 
    }

    const handleAddStudent = (e) => {
        e.preventDefault();
        const { firstName, lastName, email, password, phoneNumber, postalCode, paymentId } = formData;
        let numberOfLessons;
        let numberOfHours;
        let isRoadTest = false;
        if(formData?.packageData?.uuid === "a5b8f29d-2d5e-40fc-9b0e-6e7fe6a2f7d4" || formData?.packageData?.uuid === "612c5402-1f3c-4a8b-b4b6-5d2f0d4c62fd" || formData?.packageData?.uuid === "3604fc75-0b56-4d0f-bd2f-b2f9e59d065f"){
            isRoadTest = true;
        } else {
            isRoadTest = false;
        }

        if(formData?.homePickup) {
            if(formData?.packageData?.uuid === "4e7d83fa-9aeb-4e2a-a40c-f245c1d3aa78" || formData?.packageData?.uuid === "3a6bdc6d-78a1-4909-af22-8e0bce97c943" || formData?.packageData?.uuid === "8d3a19a1-76e3-4cbb-ae45-9d0c0b5f8e65" || formData?.packageData?.uuid === "a5b8f29d-2d5e-40fc-9b0e-6e7fe6a2f7d4" || formData?.packageData?.uuid === "612c5402-1f3c-4a8b-b4b6-5d2f0d4c62fd" || formData?.packageData?.uuid === "9f4e53df-ae32-491f-8017-2d0f933e9c13" || formData?.packageData?.uuid === "7f6296a3-a4d8-42a9-98bd-53fc80c4b989" || formData?.packageData?.uuid === "3604fc75-0b56-4d0f-bd2f-b2f9e59d065f" ){
                numberOfLessons = 5;
                numberOfHours = 10;
            } else {
                numberOfLessons = formData?.numberOfLessons;
        
                numberOfHours = formData?.numberOfLessons ? 2 * formData?.numberOfLessons : 0;
            }
        } else {
            numberOfLessons = formData?.numberOfLessons;
            numberOfHours = formData?.numberOfLessons;
        }
    
        dispatch(
            AddStudentThunk({
                firstName,
                lastName,
                fullName: `${firstName} ${lastName}`,
                email,
                password,
                isRoadTest,
                phoneNumber,
                countryCode: formData?.countryCode,
                stripePID: paymentId,
                lessonhours: numberOfHours,
                postalCode,
                instructorId: formData.instructorData?._id,
                homePickup: formData?.homePickup,
                region: formData?.packageData?.region,
                packageName: formData?.packageData?.packageName,
                packageFlow: formData?.packageData?.packageFlow,
                instructorName: formData?.instructorData?.fullName,
                lessons: numberOfLessons,
                reason: formData?.reason,
                instalmentPackageId: formData?.installmentPackage?._id
            })
        );
    };

    if (AddStudentSuccess) {
        toast.success("Student has been added successfully.");
        dispatch(ResetAddStudentData());
        dispatch(GetAllStudentsThunk());
        setModal();
    }
    if (AddStudentError) {
        toast.error(AddStudentErrorMessage);
        dispatch(GetAllStudentsThunk());
        dispatch(ResetAddStudentData());
        setModal();
    }
    const { errors, register } = useForm();

    return (
        <React.Fragment>
            {pages.page1 && (
                <Modal
                    isOpen={modal.addStudent}
                    toggle={() => setModal({ addStudent: false })}
                    className="modal-dialog-centered"
                    size="md"
                >
                    <ModalBody>
                        <div
                            onClick={(ev) => {
                                ev.preventDefault();
                                setModal({ addStudent: false });
                            }}
                            className="close cursor_pointer"
                        >
                        <Icon name="cross-sm"></Icon>
                        </div>
                        <div className="p-2">
                            <h5 className="title">Add Student</h5>
                            <div className="mt-4">
                                <Form className="row gy-4 mt-4">
                                    <Col md="6">
                                        <Button className='w-100 d-flex justify-content-center' color="primary" size="md" type="submit" onClick={(e)=> handleAddStudentReason(e, true) }>
                                            Regular Student
                                        </Button>
                                    </Col>
                                    <Col md="6">
                                        <Button className='w-100 d-flex justify-content-center' color="primary" size="md" type="submit" onClick={(e)=> handleAddStudentReason(e, false) }>
                                            Installment Student
                                        </Button>
                                    </Col>
                                </Form>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            )}

            {pages.page2 && (
                <Modal
                    isOpen={modal.addStudent}
                    toggle={() => setModal({ addStudent: false })}
                    className="modal-dialog-centered"
                    size="lg"
                >
                    <ModalBody>
                    <div
                        onClick={(ev) => {
                        ev.preventDefault();
                        setModal({ addStudent: false });
                        }}
                        className="close cursor_pointer"
                    >
                        <Icon name="cross-sm"></Icon>
                    </div>
                    <div className="p-2">
                        <h5 className="title">Add Student</h5>
                        {getInstructorListLoading ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: "50vh" }}>
                            <Spinner />
                        </div>
                        ) : (
                        <div className="mt-4">
                            {/* <Formik
                                initialValues={AddStudentInitialValues}
                                validationSchema={AddStudentValidationSchema}
                                enableReinitialize={true}
                                onSubmit={handleAddStudent}
                            >
                            {({ errors, touched }) => ( */}
                                <Form className="row gy-4 mt-4" onSubmit={handleAddStudent}>
                                    <Col md="6">
                                        <FormGroup>
                                            <label className="form-label">First Name</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="firstName"
                                                required
                                                defaultValue={formData.firstName}
                                                onChange={(e) => setFormData({...formData, firstName: e.target.value})}
                                                placeholder="Enter First Name"
                                            />
                                            {errors.firstName && <span className="invalid">{errors.firstName}</span>}
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <label className="form-label">Last Name</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="lastName"
                                                required
                                                defaultValue={formData.lastName}
                                                onChange={(e) => setFormData({...formData, lastName: e.target.value})}
                                                placeholder="Enter Last Name"
                                            />
                                            {errors.lastName && <span className="invalid">{errors.lastName}</span>}
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <label className="form-label">Phone Number</label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="phoneNumber"
                                                onChange={(e) => setFormData({...formData, phoneNumber: e.target.value})}
                                                required
                                                placeholder="Enter Phone Number"
                                                defaultValue={formData.phoneNumber}
                                            />
                                            {errors.phoneNumber && (
                                                <span className="invalid">{errors.phoneNumber}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <label className="form-label">Email</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="email"
                                                onChange={(e) => setFormData({...formData, email: e.target.value})}
                                                required
                                                defaultValue={formData.email}
                                                placeholder="Enter Email"
                                            />
                                            {errors.email && <span className="invalid">{errors.email}</span>}
                                        </FormGroup>
                                    </Col>
                                    {isRegularPackage && (
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Payment Id</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="paymentId"
                                                    onChange={(e) => setFormData({...formData, paymentId: e.target.value})}

                                                    required
                                                    defaultValue={formData.paymentId}
                                                    placeholder="pi_xxxxxxxxxxxxxxxxxxxxxxxx"
                                                />
                                                {errors.paymentId && <span className="invalid">{errors.paymentId}</span>}
                                            </FormGroup>
                                        </Col>
                                    )}
                                    <Col md="6">
                                        <FormGroup>
                                            <label className="form-label">Password</label>
                                            <input
                                                className="form-control"
                                                type="password"
                                                name="password"
                                                onChange={(e) => setFormData({...formData, password: e.target.value})}
                                                required
                                                defaultValue={formData.password}
                                                placeholder="Enter Password"
                                            />
                                            {errors.password && <span className="invalid">{errors.password}</span>}
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <label className="form-label">Postal Code</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="postalCode"
                                                onChange={(e) => setFormData({...formData, postalCode: e.target.value})}

                                                required
                                                defaultValue={formData.postalCode}
                                                placeholder="Postal Code"
                                            />
                                            {errors.postalCode && (
                                                <span className="invalid">{errors.postalCode}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <label className="form-label">Is Home Pickup</label>
                                            <div className="form-control-wrap">
                                                <RSelect
                                                    name="homePickup"
                                                    options={[{value: true ,label: "True" }, {value: false ,label: "False" }]}
                                                    defaultValue={{value: true ,label: "True" }}
                                                    onChange={(e) => {
                                                        setFormData({ ...formData, homePickup: e.value });
                                                    }}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <label className="form-label">Instructor</label>
                                            <div className="form-control-wrap">
                                                <RSelect
                                                    name="instructorId"
                                                    options={instructorsList?.instructors?.map((instructor) => {
                                                        return { value: instructor, label: instructor?.fullName };
                                                    })}
                                                    defaultValue={(() => {
                                                        const defaultInstructor = instructorsList?.instructors?.find(
                                                            (instructorData) => instructorData?._id === "625d10a6494c6f87681dc0ee"
                                                        );
                                                        return defaultInstructor
                                                            ? { value: defaultInstructor, label: defaultInstructor?.fullName }
                                                            : null;
                                                    })()}
                                                    onChange={(e) => {
                                                        setFormData({ ...formData, instructorData: e.value });
                                                    }}
                                                    />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <label className="form-label">Country Code</label>
                                            <div className="form-control-wrap">
                                                <RSelect
                                                    name="instructorId"
                                                    options={[
                                                        { value: "CA", label: "Canada" },
                                                        { value: "US", label: "United States" }
                                                    ]}
                                                    defaultValue={
                                                        { value: "CA", label: "Canada" }
                                                    }
                                                    onChange={(e) => {
                                                        setFormData({ ...formData, countryCode: e.value });
                                                    }}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    {isRegularPackage ? (
                                            <Col md="12">
                                                <FormGroup>
                                                    <label className="form-label">Package Name</label>
                                                    <div className="form-control-wrap">
                                                        <RSelect
                                                            required
                                                            name="packageName"
                                                            options={
                                                                packagesData?.packages?.map((packageDetail)=> (
                                                                {value: packageDetail, label: `${packageDetail?.name} (${packageDetail?.region === "ont" ? "Ontario" : packageDetail?.region === "bc" ? "British Columbia" : packageDetail?.region === "alb" ? "Alberta" : "New York"})`}
                                                                ))}
                                                                defaultValue={(() => {
                                                                    const defaultPackage = packagesData?.packages?.find(
                                                                        (pkgData) => pkgData?.uuid === "8d3a19a1-76e3-4cbb-ae45-9d0c0b5f8e65"
                                                                    );
                                                                    return defaultPackage
                                                                        ? { value: defaultPackage, label: `${defaultPackage?.name} (${defaultPackage?.region})` }
                                                                        : null;
                                                                })()}
                                                                onChange={(e) => {
                                                                setFormData({ ...formData, packageData: e.value, numberOfLessons: e.value?.numberOfHours });
                                                            }}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        ) : (
                                            <>
                                                <Col md="12">
                                                    <FormGroup>
                                                        <label className="form-label">Reason</label>
                                                        <div className="form-control-wrap">
                                                            <RSelect
                                                                name="homePickup"
                                                                options={invoiceReasoning}
                                                                defaultValue={{label: invoiceReasoning[0].label,  value: invoiceReasoning[0].value}}
                                                                onChange={(e) => {
                                                                    if(e.value === "Other") {
                                                                        setIsOther(true);
                                                                        setFormData({ ...formData, reason: "" });
                                                                    } else {
                                                                        setIsOther(false);
                                                                        setFormData({ ...formData, reason: e.value });
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                {isOther && (
                                                    <Col md="12">
                                                        <FormGroup>
                                                            <textarea
                                                                id="reason"
                                                                required
                                                                className="form-control"
                                                                name="reason"
                                                                onChange={(e) => { setFormData({ ...formData, reason: e.target.value })}}
                                                                defaultValue={formData.reason}
                                                                placeholder="Enter a reason"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                )}
                                                <Col md="12">
                                                    <FormGroup>
                                                        <label className="form-label">Installment Packages Name</label>
                                                        <div className="form-control-wrap">
                                                            <RSelect
                                                                name="homePickup"
                                                                options={
                                                                    installmentPackagesData?.map((installmentPackageData)=> (
                                                                    {value: installmentPackageData, label: `${installmentPackageData?.name} (${installmentPackageData?.region === "ont" ? "Ontario" : installmentPackageData?.region === "bc" ? "British Columbia" : installmentPackageData?.region === "alb" ? "Alberta" : "New York"})`}
                                                                    ))}
                                                                defaultValue={{value: `${installmentPackagesData[0]._id}` ,label: `${installmentPackagesData[0]?.name}` }}
                                                                onChange={(e) => {
                                                                    setFormData({ ...formData, installmentPackage: e.value });
                                                                }}
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </>
                                        )
                                    }
                                    {(formData?.packageData?.uuid === "7f2c08b0-4f1c-4eef-b9a6-692b4f2fd9c1" || formData?.packageData?.uuid === "b147e8e5-26bf-4a0d-8aeb-32a8a5245897") && 
                                        (
                                        <Col md="12">
                                            <FormGroup>
                                                <label className="form-label">Select number of lessons</label>
                                                <div className="form-control-wrap">
                                                    <RSelect
                                                        options={NumberOfLessonsOptions}
                                                        placeholder="choose number of lessons"
                                                        onChange={(e) => setFormData({ ...formData, numberOfLessons: e.value })}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        )
                                    }
                                    <Col size="12">
                                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                            <li>
                                                <Button disabled={AddStudentLoading} color="primary" size="md" type="submit" onClick={handleAddStudent}>
                                                    {AddStudentLoading ? <Spinner /> : "Add Student"}
                                                </Button>
                                            </li>
                                            <li>
                                                <div
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        setModal({ addStudent: false });
                                                    }}
                                                    className="link link-light cursor_pointer"
                                                >
                                                    Cancel
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Form>
                            {/* )}
                            </Formik> */}
                        </div>
                        )}
                    </div>
                    </ModalBody>
                </Modal>
            )}

        </React.Fragment>
    )
}

export default AddStudentModal;
