import React, { useEffect, useState } from 'react'
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { Icon } from '../../../components/Component';
import { useDispatch } from 'react-redux';
import ReactPaginate from 'react-paginate';
import AddInstallmentPackageModal from './AddInstallmentPackageModal';
import ViewInstallmentPackageDetailModal from './ViewInstallmentPackageDetailModal';
import UpdateInstallmentPackageModal from './UpdateInstallmentPackageModal';

const InstallmentPackages = ({ installmentPackagesData }) => {
    const dispatch = useDispatch();

    const [modal, setModal] =useState({
        add: false,
        update: false
    })
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState("");
    const [selectedPackage, setSelectedPackage] = useState();

    const formsPerPage = 10;
    const [allforms, setAllForms] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const pagesVisited = pageNumber * formsPerPage;
    const pageCount = Math.ceil(
        allforms?.filter((listing) => {
            if (onSearchText === "") {
                return listing;
            } else if (
                listing?.fullName?.toLowerCase().includes(onSearchText.toLowerCase()) ||
                listing?.creationDate?.toLowerCase().includes(onSearchText.toLowerCase())
            ) {
                return listing;
            }
            return false;
        }).length / formsPerPage
    );

    const displayListing = allforms
        .filter((listing) => {
            if (onSearchText === "") {
                return listing;
            } else if (
                listing?.fullName?.toLowerCase().includes(onSearchText.toLowerCase()) ||
                listing?.creationDate?.toLowerCase().includes(onSearchText.toLowerCase())
            ) {
                return listing;
            }
            return false;
        })
        .slice(pagesVisited, pagesVisited + formsPerPage);
    useEffect(() => {
        if(installmentPackagesData) 
            setAllForms(installmentPackagesData);
    }, [installmentPackagesData]);

    useEffect(() => {
        setPageNumber(0);
    }, [onSearchText]);

    const paginate = ({ selected }) => setPageNumber(selected);

    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    };
    const toggle = () => setonSearch(!onSearch);

    return (
        <React.Fragment>
            <div className="card-inner-group">
                <div className="card-inner">
                    <div className="card-title-group">
                        <div className="card-title">
                            <h5 className="title">Installment Packages</h5>
                        </div>
                        <div className="card-tools mr-n1">
                            <ul className="btn-toolbar">
                                <li>
                                    <Button color="primary" onClick={() => setModal({add: true})}>
                                        <Icon name="plus" />
                                        <span>Add Installment Package</span>
                                    </Button>
                                </li>
                            </ul>
                        </div>
                        <div className={`card-search search-wrap ${!onSearch ? "active" : ""}`}>
                            <div className="search-content">
                                <Button
                                    className="search-back btn-icon toggle-search"
                                    onClick={() => {
                                        setSearchText("");
                                        toggle();
                                    }}
                                >
                                    <Icon name="arrow-left"></Icon>
                                </Button>
                                <input
                                    type="text"
                                    className="form-control border-transparent form-focus-none"
                                    placeholder="Search"
                                    value={onSearchText}
                                    onChange={(e) => onFilterChange(e)}
                                />
                                <Button className="search-submit btn-icon">
                                    <Icon name="search"></Icon>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-inner p-0">
                    <table className="table table-tranx">
                        <thead>
                            <tr className="tb-tnx-head">
                            <th className="tb-tnx-id">
                                    <span className="">Package Name</span>
                                </th>
                                <th className="tb-tnx-id">
                                    <span className="">Hourly Price</span>
                                </th>
                                <th className="tb-tnx-info">
                                    <span className="tb-tnx-desc d-none d-sm-inline-block">
                                        <span>Region</span>
                                    </span>
                                    <span className="tb-tnx-date d-md-inline-block d-none">
                                        <span className="d-none d-md-block">
                                            <span>Tax Percentage</span>
                                            {/* <span>Last Updated</span> */}
                                        </span>
                                    </span>
                                </th>
                                <th className="tb-tnx-action">
                                    <span>Action</span>
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            {displayListing?.length > 0
                                ? displayListing?.map((installmentPackageData, index) => {
                                    return (
                                        <tr key={installmentPackageData?._id} className="tb-tnx-item">
                                            <td className="tb-tnx-id">
                                                <div>
                                                    <span>{installmentPackageData?.name}</span>
                                                </div>
                                            </td>
                                            <td className="tb-tnx-id">
                                                <div>
                                                    <span>{installmentPackageData?.hourlyPrice}</span>
                                                </div>
                                            </td>
                                            <td className="tb-tnx-info">
                                                <div className="tb-tnx-desc">
                                                    <span className={`title tb-tnx-status`}>{installmentPackageData?.region}</span>
                                                </div>
                                                <div className="tb-tnx-date">
                                                    <span className={`date tb-tnx-status`}>{installmentPackageData?.taxPercentage}</span>
                                                    {/* <span className={`date tb-tnx-status`}>{instructorDeviceDetail?.lastUpdated}</span> */}
                                                </div>
                                            </td>
                                            <td className="tb-tnx-action">
                                                <UncontrolledDropdown>
                                                <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                                                    <Icon name="more-h"></Icon>
                                                </DropdownToggle>
                                                <DropdownMenu right>
                                                    <ul className="link-list-plain">
                                                    <li>
                                                            <DropdownItem onClick={(ev) => { ev.preventDefault(); setModal({ view: true }); setSelectedPackage(installmentPackageData); }}>
                                                                View Detail
                                                            </DropdownItem>
                                                        </li>
                                                        <li>
                                                            <DropdownItem onClick={(ev) => { ev.preventDefault(); setModal({ update: true }); setSelectedPackage(installmentPackageData) }}>
                                                                update
                                                            </DropdownItem>
                                                        </li>
                                                    </ul>
                                                </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </td>
                                        </tr>
                                    );
                                })
                                : null}
                        </tbody>
                    </table>
                </div>
                <div className="card-inner">
                    {displayListing?.length > 0 ? (
                        <ReactPaginate
                            breakLabel="..."
                            breakClassName="pagination-btns"
                            onPageChange={(number) => paginate(number)}
                            pageRangeDisplayed={3}
                            pageCount={pageCount}
                            previousLabel="<<  Prev "
                            renderOnZeroPageCount={null}
                            nextLabel="Next >>"
                            previousClassName="pagination-next"
                            nextClassName="pagination-next"
                            activeClassName="pagination-active"
                            pageClassName="pagination mt-0"
                            pageLinkClassName="pagination-btns"
                            containerClassName="pagination justify-content-start"
                        />
                    ) : (
                        <div className="text-center">
                            <span className="text-silent">No data found</span>
                        </div>
                    )}
                </div>
            </div>
            {modal.add && <AddInstallmentPackageModal modal={modal} setModal={()=> setModal({ add: false })} />}
            {modal.view && <ViewInstallmentPackageDetailModal modal={modal} setModal={()=> setModal({ view: false })} selectedPackageData={selectedPackage} />}
            {modal.update && <UpdateInstallmentPackageModal modal={modal} setModal={()=> setModal({update: false})} selectedPackageData={selectedPackage} /> }
        </React.Fragment>
    )
}

export default InstallmentPackages;
