import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../../utils/ApiEndpoints";

// Remove In-Car Sheet 
const InitiateStudentNewInCarSheetThunk = createAsyncThunk(
    "InitiateStudentNewInCarSheet/InitiateStudentNewInCarSheetThunk",
    async ({ studentId, fullName, address, driversLicenseNumber, issueDate, dateOfBirth, licenseType, licenseImage, region }, { rejectWithValue }) => {
        
        try {
        const response = await axios.post(`${ApiEndpoints.StudentsEndPoint.inCarSheet.InitiateStudentNewInCarSheet}?studentId=${studentId}`, 
        { 
            fullName, address, driversLicenseNumber, issueDate, dateOfBirth, licenseType, licenseImage, region, instructorImage: ""
        },
        {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        });
        return response.data;
        } catch (error) {
        console.log("error", error);
        return rejectWithValue(error.response.data);
        }
    }
);

const InitiateStudentNewInCarSheetsSlice = createSlice({
    name: "InitiateStudentNewInCarSheets",
    initialState: {
        InitiateStudentNewInCarSheetData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetInitiateStudentNewInCarSheetData: (state, action) => {
        state.InitiateStudentNewInCarSheetData = [];
        state.success = false;
        state.error = false;
        state.loading = false;
        state.errorMessage = "";
        },
    },
    extraReducers: {
        [InitiateStudentNewInCarSheetThunk.pending]: (state, action) => {
        state.loading = true;
        state.success = false;
        state.error = false;
        },
        [InitiateStudentNewInCarSheetThunk.fulfilled]: (state, action) => {
        state.InitiateStudentNewInCarSheetData = [...state.InitiateStudentNewInCarSheetData, action.payload.data];
        state.success = true;
        state.loading = false;
        },
        [InitiateStudentNewInCarSheetThunk.rejected]: (state, action) => {
        state.errorMessage = action.payload.message;
        state.error = true;
        state.loading = false;
        },
    },
});

export default InitiateStudentNewInCarSheetsSlice.reducer;

export const { ResetInitiateStudentNewInCarSheetData } = InitiateStudentNewInCarSheetsSlice.actions;

export { InitiateStudentNewInCarSheetThunk };
