import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const GetInstallmentPackagesThunk = createAsyncThunk("GetInstallmentPackages/GetInstallmentPackagesThunk", async ({ region }, { rejectWithValue }) => {
    try {
        const response = await axios.get(
            `${ApiEndpoints.Configuration.GetInstallmentPackages}?region=${region ? region : ""}`,
            {
                headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
            }
        );
        return response.data;
    } catch (error) {
        console.log("error", error);
        return rejectWithValue(error.response.data);
    }
}
);

const GetInstallmentPackagesSlice = createSlice({
    name: "GetInstallmentPackages",
    initialState: {
        installmentPackagesData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetGetInstallmentPackagesData: (state, action) => {
            state.installmentPackagesData = [];
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },

    extraReducers: {
        [GetInstallmentPackagesThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [GetInstallmentPackagesThunk.fulfilled]: (state, action) => {
            state.installmentPackagesData = action.payload.packages;
            state.success = true;
            state.loading = false;
        },
        [GetInstallmentPackagesThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload.message;
            state.error = true;
            state.loading = false;
            state.installmentPackagesData = [];
        },
    },
});

export default GetInstallmentPackagesSlice.reducer;

export const { ResetGetInstallmentPackagesData } = GetInstallmentPackagesSlice.actions;

export { GetInstallmentPackagesThunk };
