import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Button, Card, DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown } from 'reactstrap';
import moment from 'moment';

import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon } from '../../../components/Component';
import Head from '../../../layout/head/Head';
import AddManualInvoiceModal from './AddManualInvoiceModal';
import { GetStudentPersonalDetailThunk } from '../../../redux/StudentsSlice/StudentPersonalDetail';
import ExpireStudentInvoiceModal from './ExpireStudentInvoiceModal';
import SendStudentInvoiceModal from './SendStudentInvoiceModal';

const ManualInvoices = () => {
    const {studentId } = useParams();
    const dispatch = useDispatch();

    const { loading, studentPersonalDetail } = useSelector(
        (state) => state.Students.getStudentPersonalDetail
    );

    const [selectedInvoiceId, setSelectedInvoiceId] = useState("");
    const [selectedInvoiceNumber, setSelectedInvoiceNumber] = useState("");
    const [modal, setModal] = useState({
        add: false,
        expire: false,
        sendInvoice: false
    })

    useEffect(() => {
        dispatch(GetStudentPersonalDetailThunk({ studentId }));
    }, [dispatch, studentId]);

    return (
        <React.Fragment>
            <Head title="Student's Invoices"></Head>
            <Block>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                        <BlockTitle page>Student's Invoices</BlockTitle>
                        <BlockDes className="text-soft">
                            <p>You have total {studentPersonalDetail?.data?.invoices.length} invoices.</p>
                        </BlockDes>
                        </BlockHeadContent>
                        <BlockHeadContent>
                        <Button color="primary" onClick={() => setModal({ add: true })}>
                            <Icon name="plus" />
                            <span>Add Invoice</span>
                        </Button>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <Card className="card-bordered card-stretch">
                    {loading ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh"}}>
                            <Spinner />
                        </div>
                    ) : (
                        <div className="card-inner-group">
                            <div className="card-inner p-0">
                                <table className="table table-tranx">
                                    <thead>
                                        <tr className="tb-tnx-head">
                                        <th className="tb-tnx-id">
                                                <span className="">#</span>
                                            </th>
                                            <th className="tb-tnx-id">
                                                <span className="">Package Name</span>
                                            </th>
                                            <th className="tb-tnx-info">
                                                <span className="tb-tnx-desc d-none d-sm-inline-block">
                                                    <span>Amount</span>
                                                </span>
                                                <span className="tb-tnx-date d-md-inline-block d-none">
                                                    <span className="d-none d-md-block">
                                                        <span>Reason</span>
                                                        <span>Payment date</span>
                                                    </span>
                                                </span>
                                            </th>
                                            <th className="tb-tnx-amount is-alt">
                                                <span className="tb-tnx-status d-none d-md-inline-block">Status</span>
                                            </th>
                                            <th className="tb-tnx-action">
                                                <span>Action</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {[...(studentPersonalDetail?.data?.invoices)]?.sort((a,b) => Number(a.invoiceNumber) - Number(b.invoiceNumber))
                                        .map((studentInvoice, index)=>{
                                            return (
                                                <tr key={studentInvoice?.id} className="tb-tnx-item">
                                                    <td className="tb-tnx-id">
                                                        <div
                                                            className=''
                                                            onClick={(ev) => {
                                                                ev.preventDefault();
                                                            }}
                                                        >
                                                            <span>{studentInvoice.invoiceNumber}</span>
                                                        </div>
                                                    </td>
                                                    <td className="tb-tnx-id">
                                                        <div
                                                            className=''
                                                            onClick={(ev) => {
                                                                ev.preventDefault();
                                                            }}
                                                        >
                                                            <span>{studentInvoice.packageName}</span>
                                                        </div>
                                                    </td>
                                                    <td className="tb-tnx-info">
                                                        <div className="tb-tnx-desc">
                                                            <span className="title">{studentInvoice?.amount}</span>
                                                        </div>
                                                        <div className="tb-tnx-date">
                                                            <span className="date">{studentInvoice?.reason}</span>
                                                            <span className="date">{studentInvoice?.paymentDate ? moment.utc(studentInvoice?.paymentDate).format("MM/DD/YYYY"): ""}</span>
                                                        </div>
                                                    </td>
                                                    <td className="tb-tnx-amount is-alt">
                                                        <div className="tb-tnx-status">
                                                            <span
                                                                className={`badge badge-dot badge-${studentInvoice.status === "paid"
                                                                    ? "success"
                                                                    : studentInvoice.status === "open" ? "warning"
                                                                    : studentInvoice.status === "void" ? ""
                                                                    : "danger"
                                                                }`}
                                                            >
                                                                {studentInvoice.status}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td className="tb-tnx-action">
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle
                                                                tag="a"
                                                                className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                                            >
                                                                <Icon name="more-h"></Icon>
                                                            </DropdownToggle>
                                                            <DropdownMenu right>
                                                                <ul className="link-list-plain">
                                                                    <li>
                                                                        <DropdownItem
                                                                            onClick={() => setModal({ view: true })}
                                                                        >
                                                                        View Detail
                                                                        </DropdownItem>
                                                                    </li>
                                                                    <li>
                                                                        <DropdownItem
                                                                        onClick={(ev) => {
                                                                            setSelectedInvoiceNumber(studentInvoice.invoiceNumber)
                                                                            setModal({ sendInvoice : true });
                                                                        }}
                                                                        >
                                                                        Send Invoice
                                                                        </DropdownItem>
                                                                    </li>
                                                                    <li>
                                                                        <DropdownItem
                                                                            onClick={(ev) => {
                                                                                setSelectedInvoiceId(studentInvoice.invoiceId)
                                                                                setSelectedInvoiceNumber(studentInvoice.invoiceNumber)
                                                                                setModal({ expire: true });
                                                                            }}
                                                                        >
                                                                            Expire
                                                                        </DropdownItem>
                                                                    </li>
                                                                </ul>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        {studentPersonalDetail?.data?.invoices.length === 0 && (
                                            <p className="p-3">No Data Found</p>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )
                    }
                </Card>
            </Block>

            {modal.add && <AddManualInvoiceModal modal={modal} setModal={()=> setModal({add: false})} region={studentPersonalDetail?.data?.packageRegion} /> }
            {modal.expire && <ExpireStudentInvoiceModal modal={modal} setModal={()=> setModal({ expire: false})} invoiceId={selectedInvoiceId} countryCode={studentPersonalDetail?.data?.countryCode} studentId={studentId} invoiceNumber={selectedInvoiceNumber} />}
            {modal.sendInvoice && <SendStudentInvoiceModal modal={modal} setModal={()=> setModal({ sendInvoice: false})} studentId={studentId} invoiceNumber={selectedInvoiceNumber} /> }
        </React.Fragment>
    )
}

export default ManualInvoices;
