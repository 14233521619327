import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const RescheduleRoadTestThunk = createAsyncThunk(
  "RescheduleRoadTest/RescheduleRoadTestThunk",
  async ({ lessonId, pickupLocation, latitude, longitude, date, startTime, endTime, instructorId, roadTestType, roadTestTime, roadTestCenterAddress }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${ApiEndpoints.LesssonsEndPoint.RescheduleRoadTest}?lessonId=${lessonId}&instructorId=${instructorId}`,
        { pickupLocation, latitude, longitude, date, startTime, endTime, roadTestType, roadTestTime, roadTestCenterAddress },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const RescheduleRoadTestSlice = createSlice({
  name: "RescheduleRoadTest",
  initialState: {
    RescheduleRoadTestData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetRescheduleRoadTestData: (state, action) => {
      state.RescheduleRoadTestData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [RescheduleRoadTestThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [RescheduleRoadTestThunk.fulfilled]: (state, action) => {
      state.RescheduleRoadTestData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [RescheduleRoadTestThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload.message;
      state.error = true;
      state.loading = false;
    },
  },
});

export default RescheduleRoadTestSlice.reducer;

export const { ResetRescheduleRoadTestData } = RescheduleRoadTestSlice.actions;

export { RescheduleRoadTestThunk };
