import React, { useEffect, useState } from 'react'
import { Button, Card, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { Icon } from '../../../components/Component';
import ReactPaginate from 'react-paginate';
import { useDispatch } from 'react-redux';
import AddRegularPackageModal from './AddRegularPackageModal';
import UpdateRegularPackageModal from './UpdateRegularPackageModal';

const RegularPackages = ({ packagesData }) => {
    const dispatch = useDispatch();

    const [selectedPackage, setSelectedPackage ] = useState();
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState("");
    const [modal, setModal] = useState({
        add: false,
        update: false,
        delete: false
    })

    const formsPerPage = 10;
    const [allforms, setAllForms] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const pagesVisited = pageNumber * formsPerPage;
    const pageCount = Math.ceil(
        allforms?.filter((listing) => {
            if (onSearchText === "") {
                return listing;
            } else if (
                listing?.fullName?.toLowerCase().includes(onSearchText.toLowerCase()) ||
                listing?.creationDate?.toLowerCase().includes(onSearchText.toLowerCase())
            ) {
                return listing;
            }
            return false;
        }).length / formsPerPage
    );

    const displayListing = allforms
        .filter((listing) => {
            if (onSearchText === "") {
                return listing;
            } else if (
                listing?.fullName?.toLowerCase().includes(onSearchText.toLowerCase()) ||
                listing?.creationDate?.toLowerCase().includes(onSearchText.toLowerCase())
            ) {
                return listing;
            }
            return false;
        })
        .slice(pagesVisited, pagesVisited + formsPerPage);
    useEffect(() => {
        if(packagesData?.packages) 
            setAllForms(packagesData?.packages);
    }, [packagesData]);

    useEffect(() => {
        setPageNumber(0);
    }, [onSearchText]);

    const paginate = ({ selected }) => setPageNumber(selected);

    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    };
    const toggle = () => setonSearch(!onSearch);

    return (
        <React.Fragment>

            <div className="card-inner-group">
                <div className="card-inner">
                    <div className="card-title-group" style={{ width: "99%"}}>
                        <div className="card-title">
                            <h5 className="title">Regular Packages</h5>
                        </div>
                        <div className="card-tools mr-n1">
                            <ul className="btn-toolbar">
                                <li>
                                    <Button color="primary" onClick={() => setModal({add: true})}>
                                        <Icon name="plus" />
                                        <span>Add Package</span>
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="card-inner p-0">
                    <table className="table table-tranx">
                        <thead>
                            <tr className="tb-tnx-head">
                                <th className="tb-tnx-id">
                                    <span className="">Package Name</span>
                                </th>
                                <th className="tb-tnx-id">
                                    <span className="">Number of Hours</span>
                                </th>
                                <th className="tb-tnx-info">
                                    <span className="tb-tnx-desc d-none d-sm-inline-block">
                                        <span>Region</span>
                                    </span>
                                    <span className="tb-tnx-date d-md-inline-block d-none">
                                        <span className="d-none d-md-block">
                                            <span>Price</span>
                                            <span>Popular</span>
                                        </span>
                                    </span>
                                </th>
                                <th className="tb-tnx-action">
                                    <span>Action</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {displayListing?.length > 0
                                ? displayListing?.map((packageDetail, index) => {
                                    return (
                                        <tr key={packageDetail?._id} className="tb-tnx-item">
                                            <td className="tb-tnx-id">
                                                <div>
                                                    <span>{packageDetail?.name}</span>
                                                </div>
                                            </td>
                                            <td className="tb-tnx-id">
                                                <div>
                                                    <span>{packageDetail?.numberOfHours}</span>
                                                </div>
                                            </td>
                                            <td className="tb-tnx-info">
                                                <div className="tb-tnx-desc">
                                                    <span className={`title tb-tnx-status`}>{packageDetail?.region}</span>
                                                </div>
                                                <div className="tb-tnx-date">
                                                    <span className={`date tb-tnx-status`}>{packageDetail?.price}</span>
                                                    <span className={`date tb-tnx-status`}>{packageDetail?.popular ? "Yes" : "No"}</span>
                                                </div>
                                            </td>
                                            <td className="tb-tnx-action">
                                                <UncontrolledDropdown>
                                                <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                                                    <Icon name="more-h"></Icon>
                                                </DropdownToggle>
                                                <DropdownMenu right>
                                                    <ul className="link-list-plain">
                                                    <li>
                                                            <DropdownItem onClick={(ev) => { ev.preventDefault(); setModal({ view: true }); }}>
                                                                View Detail
                                                            </DropdownItem>
                                                        </li>
                                                        <li>
                                                            <DropdownItem onClick={(ev) => { ev.preventDefault(); setModal({ update: true }); setSelectedPackage(packageDetail) }}>
                                                                Update
                                                            </DropdownItem>
                                                        </li>
                                                    </ul>
                                                </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </td>
                                        </tr>
                                    );
                                })
                                : null}
                        </tbody>
                    </table>
                </div>
                <div className="card-inner">
                    {displayListing?.length > 0 ? (
                        <ReactPaginate
                            breakLabel="..."
                            breakClassName="pagination-btns"
                            onPageChange={(number) => paginate(number)}
                            pageRangeDisplayed={3}
                            pageCount={pageCount}
                            previousLabel="<<  Prev "
                            renderOnZeroPageCount={null}
                            nextLabel="Next >>"
                            previousClassName="pagination-next"
                            nextClassName="pagination-next"
                            activeClassName="pagination-active"
                            pageClassName="pagination mt-0"
                            pageLinkClassName="pagination-btns"
                            containerClassName="pagination justify-content-start"
                        />
                    ) : (
                        <div className="text-center">
                            <span className="text-silent">No data found</span>
                        </div>
                    )}
                </div>
            </div>
            {modal.add && <AddRegularPackageModal modal={modal} setModal={()=> setModal({ add: false })} />}
            {modal.update && <UpdateRegularPackageModal modal={modal} setModal={() => setModal({ update: false })} packageDetail={selectedPackage} /> }
        </React.Fragment>
    )
}

export default RegularPackages;
