import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

// add Regular Package
const AddRegularPackageThunk = createAsyncThunk(
    "AddRegularPackage/AddRegularPackageThunk",
    async ({name, centralPickupNumberOfLessons, homePickupNumberOfLessons, order, region, isFullCourse, numberOfHours, isRoadTest, price, popular, homePickupPerks, centralPickupPerks}, { rejectWithValue }) => {
        try {
            const response = await axios.post(ApiEndpoints.Packages.AddRegularPackage,
                {name, centralPickupNumberOfLessons, homePickupNumberOfLessons, order, region, isFullCourse, numberOfHours, isRoadTest, price, popular, homePickupPerks, centralPickupPerks},
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
                });
            return response.data;
        } catch (error) {
            console.log("error", error);
            const errorMessage = error?.response?.data?.message || "Something went wrong";
            return rejectWithValue(errorMessage);
        }
    }
);

const AddRegularPackageSlice = createSlice({
    name: "AddRegularPackage",
    initialState: {
        addRegularPackageData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetAddRegularPackageData: (state, action) => {
            state.addRegularPackageData = [];
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [AddRegularPackageThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [AddRegularPackageThunk.fulfilled]: (state, action) => {
            state.addRegularPackageData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [AddRegularPackageThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.addRegularPackageData = [];
        },
    },
});

export default AddRegularPackageSlice.reducer;

export const { ResetAddRegularPackageData } = AddRegularPackageSlice.actions;

export { AddRegularPackageThunk };
