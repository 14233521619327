import React, { useEffect } from 'react';
import { Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle, Icon } from '../../components/Component';
import { Card } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { GetPackagesThunk } from '../../redux/StudentsSlice/GetPackagesSlice';
import { GetInstallmentPackagesThunk } from '../../redux/PackagesSlice/GetInstallmentPackagesSlice';
import RegularPackages from './RegularPackages';
import InstallmentPackages from './InstallmentPackages';
import Head from "../../layout/head/Head";

const Packages = () => {
    const dispatch = useDispatch();
    
    const { 
        loading: GetPackageLoading,
        packagesData
    } = useSelector((state)=> state.Students.getPackages);
    const { 
        loading: getInstallPackagesLoading,
        installmentPackagesData
    } = useSelector((state) => state.packages.getInstallmentPackages);

    useEffect(() => {
        dispatch(GetPackagesThunk());
        dispatch(GetInstallmentPackagesThunk({region: ""}));
    }, [dispatch]);

    return (
        <React.Fragment>
            <Head title="Packages"></Head>
            <Block className="mt-4">
                <BlockHead size="sm">
                    <BlockBetween>
                    <BlockHeadContent>
                            <BlockTitle page>Packges</BlockTitle>
                        </BlockHeadContent>
                        <BlockHeadContent>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <Card className="card-bordered card-stretch">
                    <RegularPackages packagesData={packagesData} />
                    <InstallmentPackages installmentPackagesData={installmentPackagesData} />
                </Card>
            </Block>
        </React.Fragment>
    )
}

export default Packages;
