import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

// update Regular Package
const UpdateRegularPackageThunk = createAsyncThunk(
    "UpdateRegularPackage/UpdateRegularPackageThunk",
    async ({name, packageId, centralPickupNumberOfLessons, homePickupNumberOfLessons, order, region, isFullCourse, numberOfHours, isRoadTest, price, popular, homePickupPerks, centralPickupPerks }, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${ApiEndpoints.Packages.UpdateRegularPackage}?packageId=${packageId}`,
                {name,  centralPickupNumberOfLessons, homePickupNumberOfLessons, order, region, isFullCourse, numberOfHours, isRoadTest, price, popular, homePickupPerks, centralPickupPerks},
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
                });
            return response.data;
        } catch (error) {
            console.log("error", error);
            const errorMessage = error?.response?.data?.message || "Something went wrong";
            return rejectWithValue(errorMessage);
        }
    }
);

const UpdateRegularPackagelice = createSlice({
    name: "UpdateRegularPackage",
    initialState: {
        updateRegularPackageData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetUpdateRegularPackageData: (state, action) => {
            state.updateRegularPackageData = [];
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [UpdateRegularPackageThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [UpdateRegularPackageThunk.fulfilled]: (state, action) => {
            state.updateRegularPackageData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [UpdateRegularPackageThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.updateRegularPackageData = [];
        },
    },
});

export default UpdateRegularPackagelice.reducer;

export const { ResetUpdateRegularPackageData } = UpdateRegularPackagelice.actions;

export { UpdateRegularPackageThunk };
