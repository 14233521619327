import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const GetAllPromotionsThunk = createAsyncThunk("AllPromotions/AllPromotionsThunk", async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${ApiEndpoints.PromotionsEndPoint.GetAllPromotions}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
    });
    return response.data;
  } catch (error) {
    console.log("error", error);
    return rejectWithValue(error.response.data);
  }
});

const AllPromotionsSlice = createSlice({
  name: "AllPromotions",
  initialState: {
    AllPromotionsData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetAllPromotionsData: (state, action) => {
      state.AllPromotionsData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },

  extraReducers: {
    [GetAllPromotionsThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GetAllPromotionsThunk.fulfilled]: (state, action) => {
      state.AllPromotionsData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [GetAllPromotionsThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload.message;
      state.error = true;
      state.loading = false;
    },
  },
});

export default AllPromotionsSlice.reducer;

export const { ResetAllPromotionsData } = AllPromotionsSlice.actions;

export { GetAllPromotionsThunk };
