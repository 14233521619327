import React, { useEffect, useState } from 'react';
import {useSelector, useDispatch } from 'react-redux';
import { Button, Col, Form, FormGroup, Modal, ModalBody, Row, Spinner } from 'reactstrap';
import { useParams } from 'react-router';
import DatePicker from "react-datepicker";
import toast from 'react-hot-toast';

import { Icon, RSelect } from '../../../components/Component';
import { AddStudentInvoiceThunk, ResetAddStudentInvoiceData } from '../../../redux/StudentsSlice/ManualInvoicesSlice/AddManualInvoiceSlice';
import { GetStudentPersonalDetailThunk } from '../../../redux/StudentsSlice/StudentPersonalDetail';
import { GetInstallmentPackagesThunk } from '../../../redux/PackagesSlice/GetInstallmentPackagesSlice';

const invoiceReasoning = [
    { label: "Checkout bug", value: "Checkout bug" },
    { label: "Manual installment payments", value: "Manual installment payments"  },
    { label: "Out of town road test", value: "Out of town road test"  },
    { label: "Custom package", value: "Custom package"  },
    { label: "Other", value: "Other"  },
]

const AddManualInvoiceModal = ({modal, setModal, region }) => {
    const dispatch = useDispatch();
    const {studentId } = useParams();
        const {
            loading,
            success,
            error,
            errorMessage,
        } = useSelector((state) => state.Students.studentInvoices.addStudentInvoice);
    const { 
        loading: getInstallPackagesLoading,
        installmentPackagesData
    } = useSelector((state) => state.packages.getInstallmentPackages)

    const [formValues, setFormValues] = useState({
        amount: 0,
        reason: invoiceReasoning[0].value
    });
    const [isOther, setIsOther] = useState(false);
    const [ispackageSelected, setIsPackageSelected] = useState(false);
    const [selectedInstallmentPackage, setSelectedInstallmentPackage] = useState();
    
    useEffect(()=>{
        dispatch(GetInstallmentPackagesThunk({ region }));
    },[dispatch, region]);

    const handleAddManualInvoice = (e) => {
        dispatch(AddStudentInvoiceThunk({ studentId, amount: formValues.amount, reason: formValues.reason, packageId: selectedInstallmentPackage?._id }))
    }

    if(success) {
        toast.success("Invoice has been sent successfully.");
        dispatch(GetStudentPersonalDetailThunk({ studentId }));
        dispatch(ResetAddStudentInvoiceData());
        setModal();
    }
    if(error) {
        toast.error(errorMessage);
        dispatch(ResetAddStudentInvoiceData());
        setModal();
    }
    
    return (
        <React.Fragment>
            <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
                <ModalBody>
                    <div
                        onClick={(ev) => {
                        ev.preventDefault();
                        setModal(false);
                        }}
                        className="close cursor_pointer"
                    >
                        <Icon name="cross-sm" />
                    </div>
                    <div className="p-2">
                        <h5 className="title mb-5">Add Student Invoice</h5>
                        <div className="tab-content">
                        {getInstallPackagesLoading ? (
                            <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh"}}>
                                <Spinner />
                            </div>
                        ) : (
                            <div className={`tab-pane active`} id="personal">
                                <Row className="gy-4">
                                    <Col md="12">
                                        <FormGroup>
                                            <label className="form-label">Select Package</label>
                                            <div className="form-control-wrap">
                                                <RSelect
                                                    name="selectPackage"
                                                    placeholder="Select Package"
                                                    options={installmentPackagesData?.map((installmentPackage)=>{
                                                        return {label: installmentPackage.name, value: installmentPackage}
                                                    })}
                                                    onChange={(e) => {
                                                        setIsPackageSelected(true);
                                                        setSelectedInstallmentPackage(e.value);
                                                    }}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    {ispackageSelected && (
                                        selectedInstallmentPackage.invoices.map((invoice)=>{
                                            return (
                                                <div className='d-flex justify-content-center align-items-center w-100'>
                                                    <Col md="8">
                                                        
                                                            <h6>{`Invoice #${invoice.invoiceNumber + invoice.invoiceNumber}: 
                                                                ${Number(invoice.lessonHours) > 0 ? `${Number(invoice.lessonHours)} Hour(s) of Lessons` : ``}
                                                                ${invoice.roadTest ? (Number(invoice.lessonHours) > 0 ? ` + Road Test` : `Road Test`) : ``}
                                                                ${invoice.theory ? (Number(invoice.lessonHours) > 0 ? ` + Learner's Licence Test Prep` : `Learner's Licence Test Prep`) : ``}
                                                                `} 
                                                            </h6>
                                                    
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <label className="form-label" htmlFor="amount">
                                                                Amount
                                                            </label>
                                                            <input
                                                                type="number"
                                                                id="amount"
                                                                className="form-control"
                                                                name="amount"
                                                                value={invoice.amount}
                                                                readOnly
                                                                defaultValue={invoice.amount}
                                                                placeholder="Enter an amount"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </div>
                                            )
                                        })
                                    )}

                                    <Col md="12">
                                        <FormGroup>
                                            <label className="form-label">Reason</label>
                                            <div className="form-control-wrap">
                                                <RSelect
                                                    name="homePickup"
                                                    options={invoiceReasoning}
                                                    defaultValue={{label: invoiceReasoning[0].label,  value: invoiceReasoning[0].value}}
                                                    onChange={(e) => {
                                                        if(e.value === "Other") {
                                                            setIsOther(true);
                                                            setFormValues({ ...formValues, reason: "" });
                                                        } else {
                                                            setIsOther(false);
                                                            setFormValues({ ...formValues, reason: e.value });
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    {isOther && (
                                        <Col md="12">
                                            <FormGroup>
                                                {/* <label className="form-label" htmlFor="description">
                                                    Description
                                                </label> */}
                                                <textarea
                                                    id="reason"
                                                    required
                                                    className="form-control"
                                                    name="reason"
                                                    onChange={(e) => { setFormValues({ ...formValues, reason: e.target.value })}}
                                                    defaultValue={formValues.reason}
                                                    placeholder="Enter a reason"
                                                />
                                            </FormGroup>
                                        </Col>
                                    )}
                                    <Col size="12">
                                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                            <li>
                                                <Button
                                                    disabled={loading}
                                                    color="primary"
                                                    size="md"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        handleAddManualInvoice();
                                                    }}
                                                >
                                                    {loading ? <Spinner /> : "Add Student Invoice"}
                                                </Button>
                                            </li>
                                            <li>
                                                <div
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    setModal(false);
                                                }}
                                                className="link link-light cursor_pointer"
                                                >
                                                Cancel
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </div>
                        )}
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default AddManualInvoiceModal;
